import {
  getFirestore, collection, addDoc, updateDoc, doc, getDoc, getDocs, query, where,
} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';

import {Package, Picture} from '../@types';

import {ExtractableData, OnCompleteCallback} from '.';
import {deleteFile, isUploaded, uploadPicture} from './cdn';


/**
 * This function is used to add new packages to the system. This is done in a
 * the following steps:
 * 1. Uploading the package's pictures to the CDN.
 * 2. Updating the package.gallery entry to all the uploaded Picture objects
 * 3. Uploading the package to the firestore collection `/packages`
 *
 * Uploading Package Gallery
 * -------------------------
 * This process is done recursively until all the images are uploaded, this is
 * vital due to the upload function using callbacks rather than promises thus we
 * cannot use `await` to block until an upload finishes, once we have finished
 * we replace the gallery array with the new array, then we can proceed to the
 * uploading of the package to the firestore collection.
 * 
 * The images of the packages are upladed to the cdn relative fodler `/package`
 * where all the images will be stored together for all the packages and will
 * only be referenced by the URL. This means that all the names will have to be
 * unique to make sure that images do not replace each other and erase access
 * rights to the image for another package that has the same name image.
 *
 * Uplading Package to Firestore
 * -----------------------------
 * The package is uploaded to the firestore collection `/package` where it will
 * be assigned a random id managed by firebase itself to reduce to nearly 0% the
 * chances that two computers will generate the same ID as the upload is being
 * done (would have been the case if random id generatio was being done from the
 * maching posting the package)
 *
 * @param newPackage this is the package to be added.
 * @param onComplete callback to be executed after the package is added.
 */
export const addNewPackage = async (
  newPackage: Package, onComplete: OnCompleteCallback<Package>,
): Promise<void> => {
  try {
    const uploadedPictures: Picture[] = [];
    let imageUploadCounter = 0;

    const uploadPackage = async () => {
      try {
        const db = getFirestore();
        const docReference = await addDoc(collection(db, "package"), newPackage);
        const packageId = docReference.id;
  
        // call the function and extract content from brief
        const functions = getFunctions();
        const extractBrief = httpsCallable(functions, "extract_brief");
        const result = await extractBrief({
          type: "package",
          id: packageId,
          data: {...newPackage, id: packageId},
        } as ExtractableData);

        const {data} = result.data as ExtractableData;
        await updateDoc(
          doc(db, "package", packageId),
          {...data, id: packageId},
        );
  
        onComplete(null, (await getDoc(docReference)).data() as Package);
      } catch (err) {
        onComplete(err as Error, null);
      }
    };

    const uploadPictures = () => {
      if (imageUploadCounter === newPackage.gallery.length) {
        newPackage.gallery = uploadedPictures;

        uploadPackage();

        return;
      }

      uploadPicture(
        `/package/${Date.now()}_${newPackage.gallery[imageUploadCounter].name}`,
        newPackage.gallery[imageUploadCounter],
        (err, uploadedPicture) => {
          if (err) {
            onComplete(err, null);
  
            return;
          }
  
          // add to the counter
          imageUploadCounter++;
  
          if (uploadedPicture) uploadedPictures.push(uploadedPicture);

          // recursively call this function
          uploadPictures();
        },
      );
    };

    // start the uploading process
    uploadPictures();
  } catch(err) {
    onComplete(err as Error, null);
  }
};


/**
 * This function fetches all the functions from the firestore collection
 * `/package` and yields the results/error to the callback function
 * `onComplete`.
 *
 * @param onComplete callback to be executed after the function completes the
 * fetching of the packages.
 */
export const fetchAllPackages = async (
  onComplete: OnCompleteCallback<Package[]>,
): Promise<void> => {
  const db = getFirestore();

  try {
    const packages = await getDocs(collection(db, "package"));

    onComplete(null, packages.docs.map(doc => doc.data() as Package));
  } catch (err) {
    onComplete(err as Error, null);
  }
};

/**
 * This function fetches the package with the id `id` from the firestore
 * collection `/package` and yields the results/error to the callback function
 *
 * @param id The id of the package to be fetched.
 * @param onComplete This callback gets called after the function is finished
 * execute with either the result or the error that was thrown.
 */
export const fetchPackage = async (
  id: string, onComplete: OnCompleteCallback<Package>,
): Promise<void> => {
  try {
    const db = getFirestore();

    const docReference = doc(db, "package", id);
    const packageData = (await getDoc(docReference)).data() as Package;

    onComplete(null, packageData);
  } catch (err) {
    onComplete(err as Error, null);
  }
};


export const fetchPackages = async (
  packages: string[], onComplete: OnCompleteCallback<Package[]>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const packagesRef = collection(db, "package");
    const pacakgesSnapshot = await getDocs(
      query(packagesRef, where("id", "in", packages)),
    );

    const fetchedPackages = pacakgesSnapshot.docs.map(
      doc => doc.data() as Package,
    );

    onComplete(null, fetchedPackages);
  } catch (err) {
    onComplete(err as Error, null);
  }
};

/**
 * This function updates the package with the id `id` in the firestore
 * collection. This function works in the same way as the `updateBlog` function
 * in the `/src/api/blog.ts` module. except that all the images are stored in
 * the cdn folder `/package` and the package is in the firestore collection
 * `/package`
 *
 * @param id The id of the package to be updated.
 * @param updatedPackage Theses are the new details of the package.
 * @param onComplete This callback gets called after the function is finished
 */
export const updatePackage = async (
  id: string, updatedPackage: Package, onComplete: OnCompleteCallback<Package>,
): Promise<void> => {
  try {
    const docReference = doc(getFirestore(), "package", id);
    const previousBlog = (await getDoc(docReference)).data() as Package;

    const uploadedPictures: Picture[] = [];
    let imageCounter = 0;

    const uploadPictures = async () => {
      if (imageCounter === updatedPackage.gallery.length) {
        updatedPackage.gallery = uploadedPictures;

        // compare the old blog gallery with the new blog gallery
        // if the image is not present in the new blog gallery, we need to
        // delete it from the cloud storage
        previousBlog.gallery.forEach(oldPicture => {
          const pictureFound = updatedPackage.gallery.find(
            newPicture => newPicture.url === oldPicture.url,
          );

          if (!pictureFound) {
            deleteFile(oldPicture.url);
          }
        });

        const functions = getFunctions();
        const extractBrief = httpsCallable(functions, "extract_brief");
        const result = await extractBrief({
          data: updatedPackage,
          id,
          type: "package",
        } as ExtractableData);
        const {data} = result.data as ExtractableData;
        // update the blog
        await updateDoc(docReference, {...updatedPackage, brief: data.brief});

        onComplete(null, updatedPackage);
        return;
      }

      // we loop through until we get to a point in the counter that has not
      // been uploaded yet
      if (!isUploaded(updatedPackage.gallery[imageCounter].url)) {
        uploadPicture(
          `/package/${Date.now()}_${updatedPackage.gallery[imageCounter].name}`,
          updatedPackage.gallery[imageCounter],
          (err, uploadedPicture) => {
            if (err) {
              onComplete(err, null);

              return;
            }

            uploadedPicture && uploadedPictures.push(uploadedPicture);
            imageCounter++;

            uploadPictures();
          }
        );
      } else {
        uploadedPictures.push(updatedPackage.gallery[imageCounter]);
        imageCounter++;
        uploadPictures();
      }
    };

    uploadPictures();
  } catch (err) {
    onComplete(err as Error, null);
  }
};
