import React from 'react';
import ReactDOM from 'react-dom';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import App from './App';

import './index.css';
import './App.css';
import './style/animations.css';
import './style/nav-rail.css';
import './style/text-area.css';
import './style/text-input.css';
import './style/image-upload-modal.css';
import './style/card.css';
import './style/package-card.css';

import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
  apiKey: "AIzaSyCqsKnjpC7CdQi0iQhDzXoV0WUjomzMLbE",
  authDomain: "gpsafaris-722d7.firebaseapp.com",
  projectId: "gpsafaris-722d7",
  storageBucket: "gpsafaris-722d7.appspot.com",
  messagingSenderId: "772176231353",
  appId: "1:772176231353:web:553112e86957a8ece95468",
  measurementId: "G-XLSBJNSD1R",
  databaseURL: "https://gpsafaris-722d7-default-rtdb.firebaseio.com/",
};

// register chartjs
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
