import {FC, useContext} from 'react';
import {ChevronRightIcon} from '@heroicons/react/outline';
import {Link} from 'react-router-dom';
import {Line} from 'react-chartjs-2';

import {RankTable} from '../../components';
import {AppContext} from '../../context';


export const DashboardContainer: FC = () => {
  const {state} = useContext(AppContext);

  return (
    <div
      style={{
        height: "calc(100vh - 56px)",
      }}
    >
      <div className="relative flex flex-row">
        <div className="w-full min-h-[18rem] space-y-3">
          <div className="flex flex-row items-center justify-between px-4 h-14">
            <span className="">
              <h4 className="text-xl font-semibold">
                Packages
              </h4>
              <p className="text-subtitle">
                This are the summary statistics for the packages
              </p>
            </span>
            <Link to="/packages">
              <span className="flex flex-row items-center">
                <p className="font-semibold">
                  All Packages
                </p>
                <ChevronRightIcon
                  className="ml-4 h-6 w-6"
                />
              </span>
            </Link>
          </div>

          {/* graphing and data */}
          <div className="flex flex-row px-4">
            <div className="flex-1 flex-shrink-0 h-full max-w-[500px]">
              <RankTable
                columns={["Rank",  "Package", "Views"]}
                title="Top Packages by Views"
                subtitle="Last 7 Days"
                content={state.topViewedPackages.map((topPackage, idx) => ({
                  curr: idx + 1,
                  prev: !isNaN(state.topViewedPackagesRankingDiff[idx]) ?
                    (idx + 1) - state.topViewedPackagesRankingDiff[idx] :
                    undefined,
                  second: topPackage.title,
                  third: topPackage.views.length,
                }))}
              />
            </div>

            {/* graph */}
            <div className="flex-1 ml-4 self-center">
              <Line
                datasetIdKey="label"
                data={{
                  labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                  datasets: [
                    {
                      label: "Weekly Views",
                      data: state.summary.packages.views,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Grpahing summary for blogs */}
      <div className="flex flex-row relative mt-4">
        <div className="w-full min-h-[18rem] border-b space-y-3">
          <div className="flex flex-row items-center justify-between px-4 h-14">
            <span className="">
              <h4 className="text-xl font-semibold">
                Blogs
              </h4>
              <p className="text-subtitle">
                This are the summary statistics for all the blogs on the system
              </p>
            </span>
            <Link to="/blogs">
              <span className="flex flex-row items-center">
                <p className="font-semibold">
                  All Blogs
                </p>
                <ChevronRightIcon
                  className="ml-4 h-6 w-6"
                />
              </span>
            </Link>
          </div>

          {/* graphing and data */}
          <div className="flex flex-row px-4">
            <div className="flex-1 flex-shrink-0 h-full max-w-[500px]">
              <RankTable
                columns={["Rank",  "Package", "Views"]}
                title="Top Blogs by Reads"
                subtitle="Last 7 Days"
                content={state.topReadBlogs.map((topBlog, idx) => ({
                  curr: idx + 1,
                  prev: !isNaN(state.topReadBlogsRankingDiff[idx]) ?
                    (idx + 1) - state.topReadBlogsRankingDiff[idx] :
                    undefined,
                  second: topBlog.title,
                  third: topBlog.views.length,
                }))}
              />
            </div>

            {/* graph */}
            <div className="flex-1 ml-4 self-center">
              <Line
                datasetIdKey="label"
                data={{
                  labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                  datasets: [
                    {
                      label: "Weekly Reads",
                      data: state.summary.blogs.reads
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
