import {useContext, useEffect} from 'react';
import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';
import {getAuth} from  'firebase/auth';

import {
  fetchBlogs, fetchPackages, fetchSummary, fetchWeekSummary, updateMessagingToken,
} from '../api';
import {Blog, Package} from '../@types';

import {Header, NavRail} from '../components';
import {AppContext} from '../context';
import {BlogsContainer, NewBlog, EditBlog, ViewBlog} from './blogs';
import {ChatsContainer} from './chats';
import {DashboardContainer} from './dashboard';
import {
  NewPackage, ViewPackage, PackagesContainer, EditPackage,
} from './packages';
import {CalendarRoute} from './calendar';

export {AuthContainer} from './auth';


/**
 * This is the main application container containing all the routes. This also
 * contains the wraoer BrowserRouter to scope the application in a manner that
 * makes sure that unauthenticated users cannot be able to access any route
 * until they are authenticated to reduce chances of security issues.
 *
 * This also acts as the place where the summary statisticas are fetched for the
 * application, this is a concious choice to prevent fetching and refetching of
 * information once it required on each individual page (e.g. dashboard, blogs,
 * packages) though being the same information. Thus fetching once and storing
 * the information in the app reducer and making it available to all pages
 * through the AppContext will save on fetch costs to the firestore db.
 *
 * @returns {JSX.Element}
 */
export const AppContainer = () => {
  const {methods, state} = useContext(AppContext);

  useEffect(() => {
    // we also register the user token to make sure that they can receive
    // notifications as they come to them
    const auth = getAuth();
    console.info('Registering user token for: ', auth.currentUser?.uid);
    updateMessagingToken(`${auth.currentUser?.uid}`);

    // This is the section where the summary statistics are fetched and stored
    // in the app reducer.
    // This happens in a couple of steps:
    // 1. fetch the summary statistics
    // 2. fetch the previous's week's summary statistics
    // 3. store the summary statistics in the app reducer
    // 4. Use the previous week's summary statistics to calculate the diffs
    //    between the current top 5 ranking positions to where those same
    //    packages and blogs ranked the previous week to create a ranking table.
    // 5. Store the ranking diffs in the app reducer:
    //    - topLikedPackagesRankingDiff
    //    - topViewedPackageRankingDiff
    //    - topCommentedBlogRankingDiff
    //    - topReadBlogRankingDiff
    fetchSummary((err, summary) => {
      if (err) return;

      if (!summary) return;

      const {
        blogs: {
          comments_rank,
          reads_rank,
        },
        packages: {
          views_rank,
          likes_rank,
        },
      } = summary;

      methods.setSummary(summary);

      const previousWeek = new Date();
      previousWeek.setDate(previousWeek.getDate() - 7);

      // fetch the previous week's summary statistics
      // After the previous week's summary statistics are fetched,
      // fetch the packages and blogs.
      // After each fetch for the respective rank, e.g after fetching the top
      // viewed packages, carry out the diff calculation and then store these
      // packages and the diffRankings in the reducer.
      fetchWeekSummary(previousWeek, (err, previousWeekSummary) => {
        if (err) return;

        if (!previousWeekSummary) return;

        const {
          blogs: {
            comments_rank: previousCommentsRank,
            reads_rank: previousReadsRank,
          },
          packages: {
            views_rank: previousViewsRank,
            likes_rank: previousLikesRank,
          },
        } = previousWeekSummary;

        const topCommentedBlogs: Blog[] = [];
        const topCommentedBlogsRankingDiff: number[] = [];
        const topReadBlogs: Blog[] = [];
        const topReadBlogsRankingDiff: number[] = [];
  
        const topViewedPackages: Package[] = [];
        const topViewedPackagesRankingDiff: number[] = [];
        const topLikedPackages: Package[] = [];
        const topLikedPackagesRankingDiff: number[] = [];
  
        // after getting the ranks, we will fetch the details about these
        // packages and blogs, perform the diffs and store all that data in the
        // app reducer.
        fetchBlogs(comments_rank.slice(0, 5), (err, blogs) => {
          if (err) {
            return;
          }
  
          if (!blogs) return;
          topCommentedBlogs.push(...blogs);
  
          // calculate the diffs
          blogs.forEach((blog, index) => {
            const previousBlogIndex = previousCommentsRank.indexOf(blog.id);
  
            (previousBlogIndex === -1) && topCommentedBlogsRankingDiff.push(NaN);
            (previousBlogIndex !== -1) && topCommentedBlogsRankingDiff.push(
              index - previousBlogIndex,
            );
          });

          methods.setTopCommentedBlogs(topCommentedBlogs);
          methods.setTopCommentedBlogsRankingDiff(topCommentedBlogsRankingDiff);
        });
  
        fetchBlogs(reads_rank.slice(0, 5), (err, blogs) => {
          if (err) {
            return;
          }
  
          if (!blogs) return;
          topReadBlogs.push(...blogs);
  
          blogs.forEach((blog, index) => {
            const previousBlogIndex = previousReadsRank.indexOf(blog.id);
  
            (previousBlogIndex === -1) && topReadBlogsRankingDiff.push(NaN);
            (previousBlogIndex !== -1) && topReadBlogsRankingDiff.push(
              index - previousBlogIndex,
            );
          });

          methods.setTopReadBlogs(topReadBlogs);
          methods.setTopReadBlogsRankingDiff(topReadBlogsRankingDiff);
        });
  
        // get the top viewed packages
        fetchPackages(views_rank.slice(0, 5), (err, packages) => {
          if (err) return;
  
          if (!packages) return;
  
          topViewedPackages.push(...packages);
  
          packages.forEach((fetchedPackage, idx) => {
            const previousPackageIndex = previousViewsRank.indexOf(fetchedPackage.id);
  
            (previousPackageIndex === -1) && topViewedPackagesRankingDiff.push(NaN);
            (previousPackageIndex !== -1) && topViewedPackagesRankingDiff.push(
              idx - previousPackageIndex,
            );
          });

          methods.setTopViewedPackages(topViewedPackages);
          methods.setTopViewedPackagesRankingDiff(topViewedPackagesRankingDiff);
        });
  
        fetchPackages(likes_rank.slice(0, 5), (err, packages) => {
          if (err) return;
  
          if (!packages) return;
  
          topLikedPackages.push(...packages);
  
          packages.forEach((fetchedPackage, idx) => {
            const previousPackageIndex = previousLikesRank.indexOf(fetchedPackage.id);
  
            (previousPackageIndex === -1) && topLikedPackagesRankingDiff.push(NaN);
            (previousPackageIndex !== -1) && topLikedPackagesRankingDiff.push(
              idx - previousPackageIndex,
            );
          });

          methods.setTopLikedPackages(topLikedPackages);
          methods.setTopLikedPackagesRankingDiff(topLikedPackagesRankingDiff);
        });
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      <div className="w-full h-full overflow-y-auto">
        <NavRail />
        <Header />
        {/*
          Padding to the left is added as to cater for the nav rail which is
          fixed to the left at a width of 72px.
        */}
        <div
          className="w-full pl-[72px]"
        >
          <Routes>
            <Route path="/" element={<DashboardContainer />} />
            <Route path="/chat" element={<ChatsContainer />} />
            <Route path="/blogs">
              <Route path="new" element={<NewBlog />} />
              <Route path="edit/:id" element={<EditBlog />} />
              <Route path="view/:id" element={<ViewBlog />} />
              <Route index element={<BlogsContainer />} />
            </Route>
            <Route path="/packages">
              <Route path="new" element={<NewPackage />} />
              <Route path="edit/:id" element={<EditPackage />} />
              <Route path="view/:id" element={<ViewPackage />} />
              <Route index element={<PackagesContainer />} />
            </Route>
            <Route path="/calendar" element={<CalendarRoute />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};
