import {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {getDatabase, onValue, ref} from 'firebase/database';
import {MdSend} from 'react-icons/md';

import {User} from '../../@types/user';
import {IconButton, TextBubble} from '../../components';
import {TextInput} from '../../components/input/text-input';
import {Message, MessageThread} from '../../@types';
import {markAsRead, sendMessage} from '../../api';
import {AppContext} from '../../context';

type ChatColumnProps = {
  user: User;
  setSheetOpen: Dispatch<SetStateAction<boolean>>;
};

export const ChatColumn: FC<ChatColumnProps> = ({
  setSheetOpen, user,
}) => {
  const {state} = useContext(AppContext);
  // form input control
  const [chatMessage, setChatMessage] = useState<string>("");
  const [messageThread, setMessageThread] = useState<MessageThread>({
    from: {},
    to: {},
  });
  const [allMessages, setAllMessages] = useState<Array<Message & {dir: string}>>([]);

  useEffect(() => {
    const db = getDatabase();
    const messageRef = ref(db, `messages/${user?.id}`);
    
    onValue(messageRef, (snapshot) => {
      if (snapshot.exists()) {
        setMessageThread(snapshot.val() as MessageThread);

        // after the messages are set, we also update all the to messages that
        // were unread to the new status "read"
        markAsRead(`${user?.id}`);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fromKeys = messageThread.from ? Object.keys(messageThread.from) : [];
    const toKeys = messageThread.to ? Object.keys(messageThread.to) : [];
    const fromMessages: Array<Message & {dir: string}> = fromKeys.map((key) => (
      {
        ...(messageThread.from[key]),
        dir: "left",
      }
    ));

    const toMessages: Array<Message & {dir: string}> = toKeys.map((key) => (
      {
        ...(messageThread.to[key]),
        dir: "right",
      }
    ));
    const messages = fromMessages.concat(toMessages);
    // const messages: Array<Message & {dir: string}> = messageThread
    //   .from.map((message) => ({...message, dir: "right"}))
    //   .concat(messageThread.to.map((message) => ({...message, dir: "left"})));
    const allMessages = messages
      .sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
    
    setAllMessages(allMessages);
  }, [messageThread]);

  const postMessage = () => {
    const newMessage: Message = {
      message: chatMessage,
      sender: `${state.user?.uid}`,
      status: "unread",
      timestamp: Date.now(),
    };

    sendMessage(`${user.id}`, newMessage);
    // clear the input
    setChatMessage("");
    // anytume a new message is sent, always mark the others sent before as
    // read
    markAsRead(`${user.id}`);
  };

  return (
    <div className="no-scrollbar relative flex-1 overflow-y-scroll">
      {/* Header containing the user details */}
      <div className="sticky top-0 bg-white z-10 h-[56px] px-4 flex flex-row items-center border-b shadow-sm cursor-pointer"
        onClick={() => {
          // set the sidesheet open
          setSheetOpen(true);
        }}
      >
        <div
          className="h-10 aspect-square rounded-full bg-center bg-cover bg-no-repeat"
          style={{backgroundImage: `url("${user.avatar?.url}")`}}
        />
        <span className="ml-4">
          <h6 className="font-semibold">
            {user.name}
          </h6>
          <p className="text-subtitle text-xs">
            {user.email}
          </p>
        </span>
      </div>

      {/*
        Scrollable chat view that doesnt move by itself, NOTE, the height is
        fixed to 160px which is: (height of the Header and the height of
          the chat header + height of the message bar at the bottom)
      */}
      <div
        className="no-scrollbar overflow-y-scroll flex flex-col-reverse w-full"
        style={{height: "calc(100vh - 168px)"}}
      >
        {/*
          this will render all the messages, messages from the user go to the
          left, messages from the admin go to the right
          */}
        {allMessages.map((message, idx) => (
          <TextBubble
            key={`text_bubble_${idx}`}
            message={message.message}
            align={message.dir as "left" | "right"}
          />
        ))}
      </div>

      {/* chat bar used to send messages */}
      <div
        className="bg-white z-10 h-[56px] px-4 flex flex-row items-center border-t shadow-sm"
      >
        {/*
          There is a hack bootstrapped here:
            The TextInput is wrapped in a container to prevent irregular
            behvaviour as a result of the flex row on the parent element while
            the TextInput itself is wrapped in a Fragment (<></>)
            After that, the wrapper div is made to grow to occupy as much
            space as it can, then the text-input inside is made to occupy the
            full width of whatever space this wrapper div takes up.
            After that, there is additional styling passed into the TextInput
            which goes directly to the div.text-input container
            {@see components/input/text-input.tsx}
            This will make sure that the additional styling such as the
            rounding of corners does not go to the wrappers but instead goes
            to overrriding the text-input-container class
        */}
        <div className="flex-1">
          <TextInput
            className="w-full"
            type="text"
            helper=''
            id='chat-text-area'
            inputLabel='Type a message'
            name="chat_text_area"
            onChange={(e) => setChatMessage(e.target.value)}
            value={chatMessage}
            style={{
              width: "100%",
              borderRadius: 24,
              paddingLeft: 16,
            }}
          />

        </div>

        {/* Add the IconButton component here */}
        <IconButton
          disabled={chatMessage.length === 0}
          className="ml-4 shadow-md"
          Icon={MdSend}
          onClick={postMessage}
        />
      </div>
    </div>
  );
};
