import {FC, MouseEventHandler} from 'react';
import {IconType} from 'react-icons';

type IconButtonProps = {
  disabled?: boolean;
  className?: string;
  Icon: IconType;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: "small" | "medium" | "large" | number;
};

export const IconButton: FC<IconButtonProps> = ({
  disabled = false, className = "", size = "medium", Icon, onClick,
}) => {
  const buttonSize = size === "small" ? 24 : size === "large" ? 48 : size === "medium" ? 40: size;

  return (
    <span className="bg-white">
      <button
        disabled={disabled}
        className={`flex-shrink-0 flex-grow-0 rounded-full ${
          className
        } bg-primary-container flex items-center justify-center ${
          disabled ? "bg-primary-disabled cursor-not-allowed" : "bg-primary"
        }`}
        onClick={onClick}
        style={{
          height: buttonSize,
          width: buttonSize,
        }}
      >
        <Icon
          className="text-primary"
          size={size === "small" ? 18 : 24}
        />
      </button>
    </span>
  );
};
