import {CalendarIcon} from '@heroicons/react/outline';
import {FC} from 'react';
import {IconType} from 'react-icons';
import {
  MdOutlineCollectionsBookmark, MdHome, MdRssFeed, MdChat,
} from 'react-icons/md';
import {NavLink} from 'react-router-dom';

export const NavRail: FC = () => {
  return (
    <nav
      className="z-30 absolute top-0 bottom-0 left-0 bg-primary w-[72px] pt-2"
    >
      <NavItem
        to="/"
        label="Home"
        Icon={MdHome}
      />
      <NavItem
        to="/packages"
        label="Packages"
        Icon={MdOutlineCollectionsBookmark}
      />
      <NavItem
        to="/blogs"
        label="Blogs"
        Icon={MdRssFeed}
      />
      <NavItem
        to="/chat"
        label="Chat"
        Icon={MdChat}
      />
      <NavItem
        to="/calendar"
        label="Bookings"
        Icon={CalendarIcon}
      />
    </nav>
  );
};

type NavItemProps = {
  to: string;
  label: string;
  Icon: IconType;
};

const NavItem: FC<NavItemProps> = ({to, label, Icon}) => {
  return (
    <NavLink
      to={to}
      className={({isActive}) => (isActive) ? "active-nav-item": "nav-item"}
    >
      <div
        className="w-full h-[72px] flex flex-col items-center justify-center"
        style={{
          width: 72,
          height: 72,
        }}
      >
        <Icon
          className="w-[24px] h-[24px]"
          size={24}
          style={{
            fontSize: 24,
          }}
        />
        <p className="nav-item-label text-[11px] mt-1">{label}</p>
      </div>
    </NavLink>
  );
};
