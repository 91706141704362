import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MdAdd, MdEdit} from 'react-icons/md';

import {AppContext} from '../../context';
import {Card, ContentCardsContainer, RankTable, RecommendedItemsOverlay} from '../../components';
import {Package, PackageStats} from '../../@types';
import {fetchAllPackages, fetchCategoryRecommendation, fetchPackages, fetchPackageStatistics, setCategoryRecommendations} from '../../api';

export {NewPackage} from './new';
export {ViewPackage} from './view';
export {EditPackage} from './edit';


export const PackagesContainer = () => {
  // context
  const {
    state,
    methods: {
      showSnackbar, hideSnackbar, setFabAction, removeFabAction,
      setOverlay, removeOverlay,
    },
  } = useContext(AppContext);

  // app navigation
  const navigate = useNavigate();

  // data
  const [packages, setPackages] = useState<Package[]>([]);
  const [recommendedPackages, setRecommendedPackages] = useState<Package[]>([]);
  const [savedRecommendations, setSavedRecommendations] = useState<string[]>([]);

  const [categorRecommendationStats, setCategoryRecommendationsStats] = useState<PackageStats[]>([]);

  // app UI state
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    fetchCategoryRecommendation("packages", (err, categoryRecommendations) => {
      if (err) {
        showSnackbar({
          hide: hideSnackbar,
          isError: true,
          title: "Unable to fetch recommendations",
        });

        return;
      }

      if (!categoryRecommendations) return;

      fetchPackages(categoryRecommendations, (err, payload) => {
        if (err) return;

        if (!payload) return;

        setRecommendedPackages(payload);

        const packageStats: PackageStats[] = [];
        // the package statistics are fetched recursively
        // so we can just set the state here after all are fetched
        const fetchRecPackageStats = (idx: number) => {
          if (idx === payload.length) {
            setCategoryRecommendationsStats(packageStats);

            return;
          }

          fetchPackageStatistics(payload[idx].id, (err, stats) => {
            if (err) {
              console.error(err);

              fetchRecPackageStats(idx + 1);

              return;
            }

            if (!stats) {
              fetchRecPackageStats(idx + 1);

              return;
            }

            packageStats.push(stats);
            fetchRecPackageStats(idx + 1);
          });
        };

        // this is safe since if the payload is empty it will just set an empty
        // array as the package stats
        fetchRecPackageStats(0);
      });
    });
  }, [savedRecommendations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchPackages = () => {
      setIsFetching(true);
      // fetch all the packages, enables for the method to recursively call
      // itself until packages are fetched in the event of an error
      fetchAllPackages((err, packages) => {
        setIsFetching(false);

        if (err) {
          console.error(err);
          showSnackbar({
            hide: hideSnackbar,
            isError: true,
            title: "Error fetching packages",
            action: {
              label: "Retry",
              onDismiss: fetchPackages,
            },
          });
  
          return;
        }
        
        (packages !== null) && setPackages(packages);
      });
    };

    fetchPackages();
    // set the FAB action
    setFabAction({
      action: () => {
        navigate("/packages/new");
      },
      Icon: MdAdd,
      label: "New Package",
    });

    return removeFabAction;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveRecommendations = (newRecommendations: string[]) => {
    setCategoryRecommendations(
      "packages", newRecommendations,
      (err, payload) => {
        if (err) {
          showSnackbar({
            hide: hideSnackbar,
            isError: true,
            title: "An Error occured saving recommendations",
            action: {
              label: "Retry",
              onDismiss: saveRecommendations.bind(this, newRecommendations),
            },
          });

          return;
        }

        if (!payload) return;

        showSnackbar({
          hide: hideSnackbar,
          isError: false,
          title: "Recommendations updated",
        });

        setSavedRecommendations(payload);
      },
    );
  };

  return (
    <div
      className="relative flex flex-row h-full"
      style={{
        height: "calc(100vh - 56px)",
      }}
    >
      {/* statistics columns */}
      <div
        className="no-scrollbar flex-1 max-w-[500px] flex-shrink-0 p-4 pt-2 space-y-4 overflow-y-scroll"
        style={{height: "calc(100vh - 56px)"}}
      >
        <div>
          <h6 className="font-semibold text-xl">
            Summary Statistics
          </h6>
          <p className="text-[11px] text-subtitle">
            Example summary statistics for the packages
          </p>
        </div>

        <RankTable
          columns={["Rank",  "Package", "Follow Throughs"]}
          title="Top Packages by Saves"
          subtitle="Last 7 Days"
          content={state.topLikedPackages.map((likedPackage, idx) => ({
            curr: idx + 1,
            prev: isNaN(state.topLikedPackagesRankingDiff[idx]) ?
              undefined :
              (idx +  1) - state.topLikedPackagesRankingDiff[idx],
            second: likedPackage.title,
            third: likedPackage.saves.length,
          }))}
        />

        <RankTable
          columns={["Rank",  "Package", "Views"]}
          title="Top Packages by Views"
          subtitle="Last 7 Days"
          content={state.topViewedPackages.map((viewedPackage, idx) => ({
            curr: idx + 1,
            prev: isNaN(state.topViewedPackagesRankingDiff[idx]) ?
              undefined :
              (idx +  1) - state.topViewedPackagesRankingDiff[idx],
            second: viewedPackage.title,
            third: viewedPackage.views.length,
          }))}
        />

        <RankTable
          columns={["Rank",  "Package", "Views"]}
          title="Recommended Packages"
          subtitle="Last 7 Days"
          headerAction={{
            Icon: MdEdit,
            onClick: () => {
              setOverlay(() => (
                <RecommendedItemsOverlay
                  fetchAllItems={fetchAllPackages}
                  items={recommendedPackages}
                  setItems={(items) => {
                    saveRecommendations(
                      (items as Array<Package>).map((item) => item.id),
                    )
                  }}
                  removeOverlay={removeOverlay}
                />
              ), false);
            },
          }}
          content={categorRecommendationStats.map((stats, idx) => ({
            curr: idx + 1,
            second: recommendedPackages[idx].title,
            third: stats.totalViews,
          }))}
        />
      </div>
      <ContentCardsContainer
        title="All Packages"
        description="All packages saved in the system"
        content={packages.map((tourPackage) => ({
          description: tourPackage.brief || tourPackage.content,
          title: tourPackage.title,
          image: tourPackage.gallery[0],
          id: tourPackage.id,
        }))}
      >
        {(content) => content.map((tourPackage) => (
          <Card
            key={tourPackage.id}
            description={tourPackage.description}
            image={tourPackage.image}
            title={tourPackage.title}
            onClick={() => {
              navigate(`/packages/view/${tourPackage.id}`);
            }}
          />
        ))}
      </ContentCardsContainer>
    </div>
  );
};
