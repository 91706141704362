import {FC, useContext, useState} from 'react';

import {AntDesign, Button, Input as TextInput} from '../../components';
import {AdminUser, Role} from '../../@types';
import {RoleManager, Validator} from '../../util';
import {addNewUser} from '../../api';
import {AppContext} from '../../context';

export const Signup: FC = () => {
  // context
  const {methods: {setUser}} = useContext(AppContext);

  // form state
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [reenteredPassword, setReenteredPassword] = useState<string>("");
  const [roles, setRoles] = useState<Array<Role>>([]);

  // UI state
  const [loading, setLoading] = useState<boolean>(false);

  // UI Errors
  const [signInError, setSignInError] = useState<string>("");

  const emailVerified = Validator.verifyEmail(email);
  const nameVerified = name.trim() !== "";
  const passwordVerfied = Validator.verifyPassword(password);
  const reenteredPasswordVerfied = password === reenteredPassword;
  const rolesVerified = roles.length > 0;

  const signupUser = async () => {
    setLoading(true);

    const user: AdminUser = {
      email,
      name,
      role: 0,
      uid: "",
    };

    RoleManager.setRoles(user, roles);

    try {
      const newUser = await addNewUser({
        ...user,
        password,
      });

      setUser(newUser);
    } catch (err) {
      setLoading(false);
      setSignInError((err as Error).message);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center mt-4">
        <div className="w-80 border rounded-lg p-4 space-y-4">
          <h6 className="text-subtitle text-center font-bold">
            Sign up for admin panel
          </h6>
          <TextInput
            inputLabel="Name"
            name="name"
            helper="This name will be displayed to users"
            id="signup-name"
            value={name}
            type="text"
            error={
              (!nameVerified && name !== "") ?
                "Please enter your name" :
                ""
            }
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <TextInput
            inputLabel="Email"
            name="email"
            helper="Enter your email"
            id="signup-email"
            value={email}
            type="email"
            error={
              (!emailVerified && email !== "") ?
                "Please enter a valid email" :
                ""
            }
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <TextInput
            inputLabel="Password"
            name="password"
            helper="Password must be at least 8 characters"
            id="signup-password"
            value={password}
            type="password"
            error={
              (!passwordVerfied && password !== "") ?
                "Must have 8+ characters, 1+ number, 1+ special character" :
                ""
            }
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <TextInput
            inputLabel="Reenter Password"
            name="reenter_password"
            helper="Reenter the password entered above"
            id="signup-reenter-password"
            value={reenteredPassword}
            type="password"
            error={
              (!reenteredPasswordVerfied && reenteredPassword !== "") ?
                "Passwords do not match" :
                ""
            }
            onChange={(e) => {
              setReenteredPassword(e.target.value);
            }}
          />

          <AntDesign.Select
            mode="multiple"
            size="large"
            className="w-full text-xs"
            placeholder="Select roles"
            value={roles}
            onChange={setRoles}
          >
            <AntDesign.Select.Option value="blog-writer">
              Blog Writer
            </AntDesign.Select.Option>
            <AntDesign.Select.Option value="package-manager">
              Package Manager
            </AntDesign.Select.Option>
            <AntDesign.Select.Option value="customer-relations">
              Customer Relations
            </AntDesign.Select.Option>
          </AntDesign.Select>

          <div>
            <Button
              disabled={
                !emailVerified || !nameVerified || !passwordVerfied ||
                  !reenteredPasswordVerfied || !rolesVerified || loading
              }
              className="mx-auto"
              label="Sign up"
              mode="solid"
              type="button"
              onClick={signupUser}
            />
            {signInError && (
              <p className="text-[11px] text-red-600 text-center mt-2">
                {signInError}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
