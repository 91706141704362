import {getAuth} from 'firebase/auth';
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';

import {Picture} from '../@types';

import {OnCompleteCallback} from '.';

export const uploadPicture = async (
  fileLocation: string,
  pictureToUpload: Picture, onComplete: OnCompleteCallback<Picture>
): Promise<void> => {
  console.log(`Uploading image: ${pictureToUpload.url}`);
  const auth = getAuth();
  const storage = getStorage();
  const user = auth.currentUser;

  if (user) {
    if (isUploaded(pictureToUpload.url)) {
      onComplete(
        null,
        {
          caption: pictureToUpload.caption,
          url: pictureToUpload.url,
          name: pictureToUpload.name,
        },
      );
      // since the picture is already uploaded, there is no need to continue
      // with the function, just return the normal function
      return;
    }

    if(pictureToUpload.file) {
      const fileRef = ref(storage, fileLocation);

      try {
        const result = await uploadBytes(fileRef, pictureToUpload.file);
        const dowloadUrl = await getDownloadURL(result.ref);

        const newPicture: Picture = {
          caption: pictureToUpload.caption,
          name: pictureToUpload.name,
          url: dowloadUrl,
        };

        onComplete(null, newPicture);
      } catch (err) {
        onComplete(err as Error, null);
      }
    }
  }
};

export const deleteFile = async (fileUrl: string): Promise<void> => {
  const storage = getStorage();
  const fileRef = ref(storage, fileUrl);

  try {
    await deleteObject(fileRef);

    return Promise.resolve();
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

/**
 * This is a utilitarian funtion used by uploading functions to check whether
 * an image should be uploaded based on the url provided of the image to help
 * know whether it is locally stored or already uploaded to Firebase by a
 * previous function.
 *
 * @param url the url to be confirmed whether uploaded or not.
 *
 * @returns a boolean on whether the image is uploaded or not.
 */
export const isUploaded = (url: string): boolean => {
  // returns true of the image url contains firebase storage which leads to the
  // assummption that the image has a firebase url thus is already uploaded.
  return /firebasestorage/.test(url);
};
