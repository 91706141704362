import { SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import {FC, ReactNode, useEffect, useState} from 'react';
import Lottie from 'react-lottie';

import {Picture} from '../@types';

import {TextInput} from './input/text-input';

import noContentAnimation from '../assets/no-content.json';

type ContentCardsContainerProps = {
  title: string;
  description: string;
  content: {title: string; description: string; image:Picture, id: string}[];
  children: (
    content: {title: string; description: string, image: Picture, id: string}[]
  ) => ReactNode;
};

export const ContentCardsContainer: FC<ContentCardsContainerProps> = ({
  title, description, content, children,
}) => {
  const [visibleContent, setVisibleContent] = useState<typeof content>(content);

  // UI State
  const [filterInputVisible, setFilterInputVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");

  // monitor for filter change and change visible content accordingly
  useEffect(() => {
    // early exit if filter is empty
    if (!filter) setVisibleContent(content);

    const filteredContent = content.filter(({title, description}) =>
      title.includes(filter) || description.includes(filter)
    );

    setVisibleContent(filteredContent);
  }, [filter, content]);

  return (
    <div className="border min-w-[724px] flex-1">
      <div className="w-full h-[64px] px-4 flex flex-row items-center justify-between">
        <div>
          <h6 className="font-semibold text-xl">{title}</h6>
          <p className="text-[11px] text-subtitle">{description}</p>
        </div>
        {!filterInputVisible && (
          <SearchIcon
            className={
              "dissolve h-6 w-6 " +
              `${
                content.length === 0 ?
                  "text-disabled cursor-not-allowed" :
                  "cursor-pointer"
              }`
            }
            style={{animationDuration: "0.5s"}}
            onClick={() => {
              // if there is no content, dont allow for the changing of the
              // search bar to a text input
              if (content.length === 0) return;

              setFilterInputVisible(true);
            }}
          />
        )}
        {filterInputVisible && (
          <div
            className="dissolve flex flex-row items-center"
            style={{animationDuration: "0.5s"}}
          >
            <TextInput
              helper="Filter through content"
              id="content-filter"
              inputLabel="Enter filter name"
              name="content_filter"
              onChange={(e) => {
                console.log(e.target.value);
                setFilter(e.target.value);
              }}
              type="text"
              value={filter}
            />
            <XCircleIcon
              className="ml-3 h-6 w-6 cursor-pointer"
              onClick={() => {
                setFilterInputVisible(false);
                // clear the previous input
                setFilter("");
              }}
            />
          </div>
        )}
      </div>

      {visibleContent.length > 0 && (
        <div
          className="justify-self-end flex-shrink-0 flex-grow-0 flex flex-row flex-wrap"
        >
          {children(visibleContent)}
        </div>
      )}

      {visibleContent.length === 0 && (
        <>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: noContentAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={200}
            width={200}
          />
          <h6 className="text-center text-3xl font-semibold mt-4">
            {content.length === 0 ?
              "No content found" :
              ` No content found for "${filter}"`
            }
          </h6>
        </>
      )}
    </div>
  );
};
