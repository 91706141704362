import {
  collection, getDoc, getDocs, doc, addDoc, getFirestore, updateDoc, query, where,
} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';

import {Blog, Picture} from '../@types';

import {ExtractableData, OnCompleteCallback} from '.';
import { deleteFile, isUploaded, uploadPicture } from './cdn';


/**
 * This function works nearly identical to the `addNewPackage` function in the
 * `/src/api/package.ts` file. The only difference is that this function
 * takes a `Blog` object as an argument and uploads the images to `/blog` and
 * the blog to `/blog` collection.
 *
 * @param newBlog The blog to add to the database
 * @param onComplete This is the callback executed upon completion with either
 * the error encountered while uploading the blog, or the resultant blog that
 * was successfully uploaded.
 */
export const addNewBlog = async (
  newBlog: Blog, onComplete: OnCompleteCallback<Blog>,
): Promise<void> => {
  try {
    const uploadedPictures: Picture[] = [];
    let imageCounter = 0;

    const uploadBlog = async () => {
      try {
        const db = getFirestore();
        const docReference = await addDoc(collection(db, "blog"), newBlog);

        // get the brief extract before updating the final step
        const functions = getFunctions();
        const extractBrief = httpsCallable(functions, "extract_brief");
        const result = await extractBrief({
          type: "blog",
          id: docReference.id,
          data: {...newBlog, id: docReference.id},
        } as ExtractableData);

        const {data} = result.data as ExtractableData;

        await updateDoc(docReference, {...data, id: docReference.id});

        const uploadedDoc = (await getDoc(docReference)).data() as Blog;
        onComplete(null, uploadedDoc);
      } catch (err) {
        onComplete(err as Error, null);
      }
    };

    const uploadPictures = () => {
      if (imageCounter === newBlog.gallery.length) {
        // since all the images have been uploaded we can now reassign the
        // blog.gallery to the new pictures array
        newBlog.gallery = uploadedPictures;
        uploadBlog();

        return;
      }

      uploadPicture(
        `/blog/${Date.now()}_${newBlog.gallery[imageCounter].name}`,
        newBlog.gallery[imageCounter],
        (err, uploadedPicture) => {
          if (err) {
            onComplete(err, null);

            return;
          }

          uploadedPicture && uploadedPictures.push(uploadedPicture);
          imageCounter++;

          uploadPictures();
        },
      );
    };

    // start the upload process
    uploadPictures();
  } catch (err) {
    onComplete(err as Error, null);
  }
};

/**
 * This function fetched all the blogs from the `blog` collection.
 *
 * @param onComplete Callback executed on completion with either the error
 * encountered while fetching or the resultants blogs.
 */
export const fetchAllBlogs = async (
  onComplete: OnCompleteCallback<Blog[]>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const blogs = await getDocs(collection(db, "blog"));

    onComplete(null, blogs.docs.map(doc => ({
      ...doc.data(), id: doc.id,
    }) as Blog));
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const fetchBlog = async (
  id: string, onComplete: OnCompleteCallback<Blog>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const blog = await getDoc(doc(db, "blog", id));

    onComplete(null, {...blog.data(), id} as Blog);
  } catch(err) {
    onComplete(err as Error, null);
  }
};


/**
 * Fetches all the blogs with the ids specified in the `blogs` parameter.
 *
 * @param blogs blogs to fetch (Array of ids)
 * @param onComplete This is the callback executed upon completion with either
 * the error encountered while fetching the blogs, or the resultant blogs that
 * were successfully fetched.
 */
export const fetchBlogs = async (
  blogs: string[], onComplete: OnCompleteCallback<Blog[]>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const blogsRef = collection(db, "blog");
    const blogsSnapshot = await getDocs(
      query(blogsRef, where("id", "in", blogs)),
    );

    const fetchedBlogs = blogsSnapshot.docs.map(doc => ({
      ...doc.data(), id: doc.id,
    }) as Blog);

    onComplete(null, fetchedBlogs);
  } catch (err) {
    onComplete(err as Error, null);
  }
};


/**
 * This function updates the blog with the given id, at the core, we have to
 * reconcile the old blog with the new blog. This for the most part is
 * straightforward except for the part where we have to make sure that the user
 * has not removed and/or added any images from the computer's gallery. As such,
 * we need to make sure of this by recursively trying to upload the images while
 * checking if the image has already been uploaded, if the image has not been
 * uploaded, we upload it and then add it to the new blog.
 * We also need to check for any new image that is present in the old blog
 * and is not present in the nre blog, we need to delete the image from the
 * cloud storage to reduce the amount of storage used.
 *
 * @param id The id of the blog to update
 * @param updatedBlog The blog to update
 * @param onComplete This is the callback executed upon completion with either
 * the error encountered while uploading the blog, or the resultant blog that
 * was successfully updated.
 */
export const updateBlog = async (
  id: string, updatedBlog: Blog, onComplete: OnCompleteCallback<Blog>
): Promise<void> => {
  try {
    const docReference = doc(getFirestore(), "blog", id);
    const previousBlog = (await getDoc(docReference)).data() as Blog;

    const uploadedPictures: Picture[] = [];
    let imageCounter = 0;

    const uploadPictures = async () => {
      if (imageCounter === updatedBlog.gallery.length) {
        updatedBlog.gallery = uploadedPictures;

        // compare the old blog gallery with the new blog gallery
        // if the image is not present in the new blog gallery, we need to
        // delete it from the cloud storage
        previousBlog.gallery.forEach(oldPicture => {
          const pictureFound = updatedBlog.gallery.find(
            newPicture => newPicture.url === oldPicture.url,
          );

          if (!pictureFound) {
            deleteFile(oldPicture.url);
          }
        });

        // update the blog
        const functions = getFunctions();
        const extractBrief = httpsCallable(functions, "extract_brief");
        const result = await extractBrief({
          type: "blog",
          id,
          data: updatedBlog,
        } as ExtractableData);

        const {data} = (result.data as ExtractableData);
        await updateDoc(docReference, data);

        onComplete(null, data as Blog);
        return;
      }

      // we loop through until we get to a point in the counter that has not
      // been uploaded yet
      if (!isUploaded(updatedBlog.gallery[imageCounter].url)) {
        uploadPicture(
          `/blog/${Date.now()}_${updatedBlog.gallery[imageCounter].name}`,
          updatedBlog.gallery[imageCounter],
          (err, uploadedPicture) => {
            if (err) {
              onComplete(err, null);

              return;
            }

            uploadedPicture && uploadedPictures.push(uploadedPicture);
            imageCounter++;

            uploadPictures();
          }
        );
      } else {
        uploadedPictures.push(updatedBlog.gallery[imageCounter]);
        imageCounter++;
        uploadPictures();
      }
    };

    uploadPictures();
  } catch (err) {
    onComplete(err as Error, null);
  }
};
