import Lottie from "react-lottie";

import uploadingAnimation from '../assets/upload.json';

export const Uploading = () => {
  return (
    <div className="bg-white space-y-4 rounded-2xl p-4 w-[300px] aspect-square flex flex-col items-center">
      <Lottie
        options={{
          animationData: uploadingAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={200}
        width={200}
      />
      <h2 className="text-center font-semibold text-3xl">Uploading...</h2>
    </div>
  );
};
