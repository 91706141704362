import {FC} from 'react';
import {CheckIcon, XIcon} from '@heroicons/react/outline';
import {IconType} from 'react-icons';

type ChipProps = {
  label: string;
  className?: string;
  Icon?: IconType;
};

export const Chip: FC<ChipProps> = ({
  label, className = "", Icon
}) => {
  return (
    <div
      className={
        "slide-in bg-primary bg-opacity-20 text-primary border-primary " +
        "font-semibold flex flex-row items-center h-8 border rounded-md " +
        "hover:shadow-sm cursor-pointer " +
        className
      }
    >
      {Icon && (
        <Icon
          className="h-[18px] w-[18px] ml-2"
          size={24}
        />
      )}
      <p className={`${Icon ? "ml-2" : "ml-4"} mr-4`}>
        {label}
      </p>
    </div>
  );
};

type InputChipProps = {
  label: string;
  className?: string;
  onRemove: () => void;
};


export const InputChip: FC<InputChipProps> = ({
  label, className = "", onRemove,
}) => {
  return (
    <div
      className={
        "slide-in bg-primary bg-opacity-20 text-primary border-primary " +
        "font-semibold flex flex-row items-center h-8 pl-3 pr-2 border " +
        "rounded-md hover:shadow-sm cursor-pointer " +
        className
      }
    >
      <p className="mr-2">
        {label}
      </p>
      <XIcon
        className="h-[18px] w-[18px]"
        onClick={onRemove}
      />
    </div>
  );
};

type FilterChipProps = {
  label: string;
  active: boolean;
  className?: string;
  onClick: () => void;
};

export const FilterChip: FC<FilterChipProps> = ({
  label, className = "", active, onClick,
}) => {
  return (
    <div  
      className={
        "slide-in bg-primary bg-opacity-20 text-primary border-primary " +
        "font-semibold flex flex-row items-center h-8 border rounded-md " +
        "hover:shadow-sm cursor-pointer " +
        className
      }
      onClick={onClick}
    >
      <CheckIcon
        className={`${(active) ? "h-[18px] w-[18px] mx-2": "h-0 w-0]"}`}
        style={{
          transitionDuration: "75ms",
          transitionProperty: "height width",
          transitionTimingFunction: "linear",
        }}
      />
      <p
        className={`mr-4 ${active ? "ml-0": "ml-4"}`}
      >
        {label}
      </p>
    </div>
  );
};
