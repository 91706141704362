import {FC} from 'react';
import {IconType} from 'react-icons';


type StatsCardProps = {
  Icon?: IconType;
  title: string;
  value: number;
  suffix: string;
};

export const StatsCard: FC<StatsCardProps> = ({
  Icon, suffix, title, value,
}) => {
  return (
    <div
      className={
        "dissolve w-full shadow-sm border rounded-xl p-4 space-y-4 " +
        "bg-secondary bg-opacity-30 border-l-8 border-l-secondary"
      }
    >
      <p className="text-sm text-subtitle">
        {title}
      </p>

      <span
        className="slide-in flex flex-row items-center space-x-2 text-primary"
      >
        {Icon && (
          <Icon
            className="h-8 w-8 text-primary"
          />
        )}
        <h3 className="font-semibold text-3xl text-primary">{value}</h3>
        <h3 className="font-semibold text-3xl text-primary">{suffix}</h3>
      </span>
    </div>
  );
};
