import {FC, useContext, useEffect, useState} from 'react';
import {getDatabase, onValue, ref} from 'firebase/database';
import {ChevronDownIcon} from '@heroicons/react/outline';

import {AntDesign, ListItem, ReservationModal, SideSheet} from '../../components';
import {Chat, Package, PopulatedChat, Reservation} from '../../@types';
import {User} from '../../@types/user';
import {fetchPackage, fetchUserReservations, populateChatThreads} from '../../api';

import {ChatColumn} from './chat-column';
import { AppContext } from '../../context';


export const ChatsContainer: FC = () => {
  // UI State
  const [sheetOpen, setSheetOpen] = useState<boolean>(false);
  const [dropdownExpanded, setDropdownExpanded] = useState<boolean>(false);

  // Firebase state
  const [chats, setChats] = useState<Chat>({});

  const [chatThreads, setChatThreads] = useState<Array<PopulatedChat>>([]);

  // selected users
  const [selectedUser, setSelectedUser] = useState<User>();

  // reservations made by a user
  const [userReservations, setUserReservations] = useState<Reservation[]>([]);

  useEffect(() => {
    const db = getDatabase();
    const chatRef = ref(db, "chat");

    onValue(chatRef, (snapshot) => {
      if (!snapshot.exists()) {
        console.log("The chats ref does not exist");
        return;
      }
      setChats(snapshot.val());
    });
  }, []);

  useEffect(() => {
    populateChatThreads(chats, (err, populatedChatThreads) => {
      if (err) {
        console.error(err);

        return;
      }

      if (!populatedChatThreads) {
        return;
      }

      // NOTE: Due to the use of the firebase SDK, the order of the chats is
      // not guaranteed. As such we need to sort the chats by the last message
      // timestamp.
      const sortedMessages = populatedChatThreads.sort((a, b) => {
        return b.mostRecentMessage.timestamp - a.mostRecentMessage.timestamp;
      });

      setChatThreads(sortedMessages);
    });
  }, [chats]);

  // anytime a new user is selected, we fetch their associated reservations
  useEffect(() => {
    if (!selectedUser) return;

    // first, unset the reservations to prevent them being shown in the UI until
    // we have fetched the new ones
    setUserReservations([]);

    fetchUserReservations(selectedUser.id, (err, payload) => {
      if (err) {
        console.error(err);

        return;
      }

      if (!payload) return;

      setUserReservations(payload);
    });
  }, [selectedUser]);
  
  return (
    <div
      className="relative flex flex-row"
      style={{
        width: "calc(100vw - 72px)",
        height: "calc(100vh - 56px)",
      }}
    >
      {/* Chats Column */}
      <div className="no-scrollbar w-[344px] border-r h-full overflow-y-scroll">
        <h6 className="font-semibold text-3xl m-4">
          Chats
        </h6>
        {chatThreads.length === 0 && (
          <AntDesign.Empty
            description="You don't have any chats yet"
          />
        )}
        {chatThreads.map((chat, i) => (
          <ListItem
            key={chat.user.id}
            avatar={chat.user.avatar}
            title={chat.user.name}
            description={chat.mostRecentMessage.message}
            badge={chat.from?.length ? chat.from.length : undefined}
            onClick={() => setSelectedUser(chat.user)}
          />
        ))}
      </div>

      {/* Message column */}
      {selectedUser && (
        <ChatColumn
          setSheetOpen={setSheetOpen}
          user={selectedUser}
        />
      )}

      {/* Side sheet */}
      <SideSheet
        title={`${selectedUser?.name}'s Profile`}
        isOpen={sheetOpen}
        onClose={() => setSheetOpen(false)}
      >
        <div
          className={
            "h-48 aspect-square border rounded-full mx-auto bg-center " +
            "bg-cover bg-no-repeat"
          }
          style={{
            backgroundImage: `url(${selectedUser?.avatar?.url})`,
          }}
        />

        <div className="space-y-1 text-center">
          <h4>{selectedUser?.name}</h4>
          <p className="text-subtitle">{selectedUser?.email}</p>
        </div>

        {/* dropdown 0f the content */}
        <div className="space-y-2">
          <span
            className="flex flex-row justify-between items-center cursor-pointer"
            onClick={() => setDropdownExpanded(!dropdownExpanded)}
          >
            <h6 className="text-xs font-semibold text-subtitle">
              Booked packages
            </h6>

            <ChevronDownIcon
              className={`h-3 w-3 ${
                  dropdownExpanded ? "rotate-180" : "rotate-0"
                } transition-[transform]`
              }
            />
          </span>

          {dropdownExpanded && (
            <div
              className={`expand space-y-2`}
            >
              {userReservations.length === 0 && (
                <AntDesign.Empty
                  description="This user has not booked any packages"
                />
              )}
              {userReservations.map((reservation) => (
                <BookedPackageListItem
                  key={reservation.id}
                  reservation={reservation}
                />
              ))}
            </div>
          )}
        </div>

      </SideSheet>
    </div>
  );
};

type BookedPackageListItemProps = {
  reservation: Reservation;
};

const BookedPackageListItem: FC<BookedPackageListItemProps> = ({
  reservation,
}) => {
  const {methods} = useContext(AppContext);

  const [tourPackage, setTourPackage] = useState<Package>();

  // after it is mounted we fetch the package details
  useEffect(() => {
    fetchPackage(reservation.package, (err, payload) => {
      if (err) {
        console.error(err);

        return;
      }

      if (!payload) return;

      setTourPackage(payload);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        "slide-in w-full h-[72px] flex flex-row items-center pr-4 " +
        "overflow-hidden shadow-md border cursor-pointer hover:bg-black " +
        "hover:bg-opacity-5"
      }
      onClick={() => {
        methods.setOverlay(() => (
          <ReservationModal
            reservation={reservation}
          />
        ), true);
      }}
    >
      <div
        className="h-full w-[180px] bg-center bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url("${tourPackage?.gallery[0].url}")`,
        }}
      >
      </div>

      <p
        className={
          "whitespace-nowrap overflow-ellipsis overflow-hidden font-semibold " +
          "ml-4"
        }
      >
        {tourPackage?.title}
      </p>
    </div>
  );
};
