import {getDoc, getFirestore, doc} from "firebase/firestore";

import {BlogStats, PackageStats} from "../@types";

import {OnCompleteCallback} from ".";
import {generateWeekId} from "./summary";

// The statistics are stored summaries created by a cloud function that will
// run in intervals as controlled by the Google Cloud scheduler.
// The main point is to have statistics that are for each package based on the
// week and the type of package. As such, the structure of the stats is:
//
// /stats/:week-id/blog|package/:item-id
//
// - week-id is as generated by the generateWeekId function which takes a date
//   and retutns the week id in the format of YYYY-MM-DD with the date being the
//   previous Sunday (or the Sunday that is the date) as the id.
// - blog|package is the type of item, blog or package.
// - item-id is the id of the item.


export const fetchBlogStatistics = async (
  blogId: string, onComplete: OnCompleteCallback<BlogStats>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const blogStatsRef = doc(
      db, "stats", generateWeekId(new Date()), "blog", blogId,
    );
    const blogStats = (await getDoc(blogStatsRef)).data() as BlogStats;
    onComplete(null, blogStats);
  } catch (err) {
    onComplete(err as Error, null);
  }
};


export const fetchPackageStatistics = async (
  packageId: string, onComplete: OnCompleteCallback<PackageStats>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const blogStatsRef = doc(
      db, "stats", generateWeekId(new Date()), "package", packageId,
    );
    const blogStats = (await getDoc(blogStatsRef)).data() as PackageStats;
    onComplete(null, blogStats);
  } catch (err) {
    onComplete(err as Error, null);
  }
};
