import {createContext} from 'react';

import {AppReducerFunctions, AppState, INITIAL_STATE} from '../reducers';

export const AppContext = createContext<{
  state: AppState,
  methods: AppReducerFunctions,
}>({
  state: INITIAL_STATE,
  methods: {
    setUser: () => {},
    setOverlay: () => {},
    removeOverlay: () => {},
    hideSnackbar: () => {},
    showSnackbar: () => {},
    removeHeaderActions: () => {},
    setHeaderActions: () => {},
    removeFabAction: () => {},
    setFabAction: () => {},
    setSummary: () => {},
    setTopCommentedBlogs: () => {},
    setTopCommentedBlogsRankingDiff: () => {},
    setTopLikedPackages: () => {},
    setTopLikedPackagesRankingDiff: () => {},
    setTopReadBlogs: () => {},
    setTopReadBlogsRankingDiff: () => {},
    setTopViewedPackages: () => {},
    setTopViewedPackagesRankingDiff: () => {},
  },
});
