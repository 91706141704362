import {Dispatch, FC, SetStateAction, useContext, useState} from 'react';

import {signInUser} from '../../api';
import {Button, Input as TextInput} from '../../components';
import {AppContext} from '../../context';
import {Validator} from '../../util';

type LoginProps = {
  setSigninType: Dispatch<SetStateAction<"signin" | "signup" | "reset">>;
};

export const Login: FC<LoginProps> = ({setSigninType}) => {
  // context
  const {methods: {setUser}} = useContext(AppContext);

  // form state
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const emailVerified = Validator.verifyEmail(email);
  const passwordVerfied = password !== "";

  // UI State
  const [loading, setLoading] = useState<boolean>(false);

  // UI Error
  const [loginError, setLoginError] = useState<string>("");

  const loginUser = async () => {
    setLoading(true);

    try {
      const user = await signInUser(email, password);
      setUser(user);
    } catch (err) {
      setLoading(false);
      setLoginError((err as Error).message);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center mt-4">
        <div className="w-80 border rounded-2xl p-4 space-y-4">
          <h6 className="text-subtitle text-center font-bold">
            Log into the admin panel
          </h6>
          <TextInput
            inputLabel="Email"
            name="email"
            helper="Enter your email"
            id="email"
            value={email}
            type="email"
            error={
              (!emailVerified && email !== "") ?
                "Please enter a valid email" :
                ""
            }
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <TextInput
            inputLabel="Password"
            name="password"
            helper="Enter your password"
            id="password"
            value={password}
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <div>
            <Button
              disabled={!emailVerified || !passwordVerfied || loading}
              className="mx-auto"
              label="Log in"
              mode="solid"
              type="button"
              onClick={loginUser}
            />

            {loginError && (
              <p className="text-red-600 text-xs text-center mt-2">
                {loginError}
              </p>
            )}

            <span
              className="flex flex-row space-x-1 items-center justify-center"
            >
              <p>Forgot password?</p>
              <p className="text-blue-500" onClick={setSigninType.bind(this, "reset")}>Reset password</p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
