import {FC, MouseEventHandler} from 'react';
import {IconType} from 'react-icons';

type InnerButtonProps = {
  Icon?: IconType;
  className?: string;
  disabled?: boolean;
  label: string;
  type: "submit" | "button" | "reset";
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const OutlinedButton: FC<InnerButtonProps> = ({
  Icon, label, type, className = "", disabled, onClick,
}) => {
  return (
    <button
      disabled={disabled}
      className={
        "flex flex-row items-center h-9 min-w-[36px] px-4 border-primary " +
        "bg-transparent rounded-lg " + className +
        (disabled ? " border-disabled text-disabled cursor-not-allowed" : "")
      }
      type={type}
      onClick={onClick}
    >
      {Icon && (
        <Icon
          className="text-primary mr-3 h-[18px] w-[18px] flex-shrink-0"
          size={18}
        />
      )}
      <p className="font-semibold text-primary whitespace-nowrap">
        {label}
      </p>
    </button>
  );
};

const SolidButton: FC<InnerButtonProps> = ({
  Icon, label, type, className, disabled, onClick,
}) => {
  return (
    <button
      className={
        "flex flex-row items-center h-9 min-w-[36px] px-4 bg-primary " +
        "text-white rounded-lg shadow-md " + className +
        (disabled ? " bg-disabled cursor-not-allowed" : "")
      }
      type={type}
      onClick={onClick}
    >
      {Icon && <Icon className="mr-3 flex-shrink-0 h-[18px] w-[18px]" size={18} />}
      <p className="font-semibold whitespace-nowrap">
        {label}
      </p>
    </button>
  );
};

type ButtonProps = InnerButtonProps & {
  mode: "outline" | "solid";
};

export const Button: FC<ButtonProps> = (props) => {
  return (
    <>
      {props.mode === "outline" && (
        <OutlinedButton {...props} />
      )}
      {props.mode === "solid" && (
        <SolidButton {...props} />
      )}
    </>
  );
};
