import {FC, useEffect, useState} from 'react';

type TypeWriterProps = {
  text: string;
  onComplete: () => void;
};

let counter = 0;

export const TypeWriter: FC<TypeWriterProps> = ({text, onComplete}) => {
  // const [counter, setCounter] = useState<number>(0);
  const [visibleText, setVisibleText] = useState<string>('');

  useEffect(() => {
    const interval = setTimeout(() => {
      if (counter === text.length) {
        // set a timeout of 2 seconds before calling on complete to allow the
        // user time to read the text
        setTimeout((
        ) => {
          onComplete();
        }, 1500);
        clearInterval(interval);
        return;
      }

      counter += 1;
      setVisibleText(text.substring(0, counter));
      // setCounter(counter + 1);
      // console.log(text.substring(0, counter));

      return () => clearTimeout(interval);
    }, 100);
  }, [visibleText]); // eslint-disable-line react-hooks/exhaustive-deps

  // reset the visibleText as soon as the text is complete
  useEffect(() => {
    setVisibleText('');
    counter = 0;
  }, [text]);

  return (
    <code className="text-[48px] font-semibold transitions-all transition-all ease-linear">
      {visibleText}
    </code>
  );
};
