import {FC, useState} from 'react';

export type SnackbarProps = {
  title: string;
  isError: boolean;
  action?: {
    label: string;
    onDismiss: () => void;
  };
  hide: () => void;
};

export const Snackbar: FC<SnackbarProps> = ({
  action, isError, title, hide,
}) => {
  const [timeoutHandle, ] = useState<NodeJS.Timeout>(
    setTimeout(() => {
      hide();
    }, 5000),
  );

  return (
    <div
      className={
        `snackbar absolute left-[88px] bottom-8 w-[344px] min-h-[48px] max-h-[68px] py-4 ${
          ""
        } shadow-lg rounded-md ${
          isError ?
            'bg-red-600 text-white' :
            'bg-primary text-secondary'
        } flex flex-row items-center justify-between pl-4 pr-2 z-30`
      }
    >
      <p className="text-white">{title}</p>

      <button
        className={
          `font-semibold mr-4 border-none bg-transparent flex-shrink-0 ${
            isError ?
              "text-black" :
              "text-inherit"
          } cursor-pointer`
        }
        onClick={() => {
          action && action.onDismiss();
          timeoutHandle && clearTimeout(timeoutHandle);
          hide();
        }}
      >
        {action && action.label.toUpperCase()}
        {!action && "DISMISS"}
      </button>
    </div>
  );
};
