import {FC, useContext} from "react";

import {Picture} from "../@types";
import {AppContext} from "../context";
import {ImageCarouselOverlay} from "./image-carousel-overlay";

type ImageCollectionOverviewProps = {
  pictures: Picture[];
};

export const ImageCollectionOverview: FC<ImageCollectionOverviewProps> = ({
  pictures,
}) => {
  // context
  const {methods} = useContext(AppContext);

  const viewableImages = pictures.slice(0, 4);
  const excessImages = pictures.length - 4;

  return (
    <div className="slide-in grid grid-cols-4">
      {viewableImages.map((picture, idx) => (
        (idx < 3) ?
          <div
            key={picture.url}
            className="w-full aspect-square p-4 border bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${picture.url})`,
            }}
          >
          </div>
        :
          <div
            key={picture.url}
            className={
              "relative w-full aspect-square p-4 border bg-center bg-cover bg-no-repeat"
            }
            style={{
              backgroundImage: `url(${picture.url})`,
            }}
          >
            <div
              className={
                "absolute top-0 right-0 bottom-0 left-0 bg-scrim " +
                "flex flex-row items-center justify-center cursor-pointer"
              }
              onClick={() => {
                methods.setOverlay(() => (
                  <ImageCarouselOverlay
                    pictures={pictures}
                    removeOverlay={methods.removeOverlay}
                  />
                ), true);
              }}
            >
              <h6 className="font-semibold text-xl text-white text-center">
                +{excessImages}
              </h6>
            </div>
          </div>
      ))}
    </div>
  );
};
