import {FC} from 'react';
import Lottie from "react-lottie";

import loadingAnimation from '../assets/loading.json';

export const Loading: FC = () => {
  return (
    <Lottie
      options={{
        animationData: loadingAnimation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      height={200}
      width={200}
    />
  );
};
