import {FC, useState} from 'react';
import {CalendarIcon, TrashIcon, XIcon} from "@heroicons/react/outline";

import {Button} from "./button";
import {AntDesign } from '.';
import {TextInput} from './input/text-input';
import {TextArea} from './input/text-area';
import { BsCalendar } from 'react-icons/bs';
import { Itinerary } from '../@types';

type TimelineEditProps = {
  items: Itinerary[],
  removeOverlay: () => void;
  onSave: (items: Itinerary[]) => void;
};

export const TimelineEdit: FC<TimelineEditProps> = ({
  items, onSave,removeOverlay,
}) => {
  const [events, setEvents] = useState<Itinerary[]>(items);

  // Form input control
  const [dayNumber, setDayNumber] = useState<number>(0);
  const [dayTime, setDayTime] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  // adds event and sorts them to make sure that they are in the correct order
  const addEvent = (eventToBeAdded: Itinerary): void => {
    const newEvents = [...events, eventToBeAdded];
    newEvents.sort((a, b) => (
      Number(`${a.dayNumber}${a.time.replace(":", "")}`) -
      Number(`${b.dayNumber}${b.time.replace(":", "")}`)
    ));
    setEvents(newEvents);
  };

  // removes events
  const removeEvent = (idx: number) => {
    setEvents(events.filter((_, i) => i !== idx));
  };

  return (
    <div
      className="p-4 border shadow-lg min-w-[344px] max-w-[560px] max-h-[560px] overflow-y-auto bg-white"
    >
      <div
        className="h-14 flex flex-row items-center justify-between border-b sticky top-0 z-10 bg-white"
      >
        <div className="flex flex-row items-center">
          <XIcon
            className="h-6 w-6 text-black cursor-pointer"
          />
          <h4 className="ml-3 text-xl font-semibold">Edit itinerary</h4>
        </div>


        <Button
          label="Save"
          mode="outline"
          type="button"
          onClick={() => {
            // save the events then remove the overlay
            onSave(events);
            removeOverlay();
          }}
        />
      </div>

      <div className="space-y-4">
        {/* a row with the day number, time and title of the itinerary */}
        <div className="space-y-2 mt-4">
          <div className="flex flex-row items-center justify-evenly space-x-4">
            <AntDesign.InputNumber
              className="flex-1"
              value={dayNumber}
              placeholder="Day Number"
              onChange={(value) => {
                setDayNumber(value);
              }}
            />
            <AntDesign.TimePicker
              className="flex-1"
              onChange={(value) => {
                if (!value)
                  return;
                
                setDayTime(value.format("HH:mm"));
                console.info("Time selected ", value.format("HH:mm"));
              }}
            />
          </div>
          <TextInput
            inputLabel="title of event"
            helper="Brief title of the event"
            id="event-title"
            name="event_title"
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />

          <TextArea
            inputLabel="Description of event"
            helper="Brief description of the event"
            id="event-description"
            name="event_description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />

          <Button
            disabled={!dayNumber || !dayTime || !title || !description}
            className="mx-auto"
            label="Add Event"
            mode="solid"
            type="button"
            Icon={BsCalendar}
            onClick={() => {
              const eventToBeAdded: Itinerary = {
                dayNumber,
                description,
                time: dayTime,
                title,
              };

              // add event
              addEvent(eventToBeAdded);

              // clear all the form inputs
              setDayNumber(0);
              setTitle("");
              setDescription("");
            }}
          />
        </div>

        <hr />

        {/*
          events that have already been added (only shown when there are events)
          NOTE: Using a truthy value for events.length leads to it being
          rendered as a node
        */}
        {events.length > 0 && (
          <div className="space-y-2">
            <span className="flex flex-row items-center h-[48px]">
              <CalendarIcon
                className="h-6 w-6 mr-4"
              />
              <h6 className="font-semibold text lg">
                Added Events
              </h6>
            </span>

            {/* event items */}
            {events.map((event, idx) => (
              <TimelineEvent
                key={`te_${idx}`}
                idx={idx}
                event={event}
                remove={removeEvent}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

type TimelineEventProps = {
  idx: number;
  event: Itinerary;
  remove: (idx: number) => void;
};

const TimelineEvent: FC<TimelineEventProps> = ({
  event, idx, remove,
}) => {
  return (
    <div className="slide-in flex flex-row items-center border-b pb-2">
      <span className="justify-self-start flex flex-col items-center">
        <h6 className="text-xs">Day</h6>
        <p className="font-semibold">{event.dayNumber}</p>
      </span>
      <p className="mx-4 flex-1">
        {event.title}
      </p>
      <TrashIcon
        className="h-6 w-6 justify-self-end text-red-600 cursor-pointer"
        onClick={remove.bind(this, idx)}
      />
    </div>
  );
};
