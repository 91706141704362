import {FC, useState} from "react";
import {TrashIcon, XCircleIcon} from "@heroicons/react/outline";

import {Button} from "./button";
import {TextArea} from "./input";
import {Picture} from "../@types";

type ImageUploadModalProps = {
  title: string;
  images: Picture[];
  onSave: (images: Picture[]) => void;
  removeOverlay: () => void;
};

export const ImageUploadModal: FC<ImageUploadModalProps> = ({
  title, images, onSave, removeOverlay,
}) => {
  // Form input control
  const [imageCaption, setImageCaption] = useState<string>("");
  const [chosenImageFile, setChosenImageFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");

  // maintained pictures
  const [pictures, setPictures] = useState<Picture[]>([...images]);

  // function used to splice out an image
  const removeImage = (idx: number) => {
    const newPictures = [...pictures];
    newPictures.splice(idx, 1);
    setPictures(newPictures);
  };

  return (
    <div
      className={
        "no-scrollbar w-[560px] max-h-[560px] py-4 pt-0 rounded-lg border " +
        "bg-white shadow space-y-4 overflow-y-scroll"
      }
    >
      <div
        className={
          "h-14 bg-white flex flex-row items-center w-full sticky top-0 " +
          "border-b shadow-md w-full px-4"
        }
      >
        <XCircleIcon
          className="h-9 w-9 cursor-pointer"
          onClick={() => {
            // close the overlay
            removeOverlay();
            onSave(pictures);
          }}
        />
        <h4 className="text-2xl font-semibold flex-1 text-center">
          {title}
        </h4>
      </div>
      <div className="space-y-2 z-10 bg-white pb-2 px-4">
        <div className="p-4 border space-y-4">
          <label htmlFor="image-picker" className="cursor-pointer">
            <div
              className="w-full h-48 border bg-center bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
            >
              {/*
                if there is no image chosen we will reflect that in the UI
              */}
            </div>
            <input
              hidden
              type="file"
              name="image_picker"
              id="image-picker"
              accept="image/jpeg,image/png"
              onChange={(e) => {
                if (!e.target.files) {
                  setImageUrl("");
                  setChosenImageFile(undefined);
                
                  return;
                }

                const file = e.target.files[0];
                setImageUrl(URL.createObjectURL(file));
                setChosenImageFile(file);
              }}
            />
          </label>
          <div>
            <TextArea
              helper="Enter a description for your image"
              inputLabel="Image caption"
              name="caption"
              value={imageCaption}
              id="caption"
              onChange={(e) => setImageCaption(e.target.value)}
            />
          </div>
        </div>
        <Button
          disabled={!chosenImageFile || !imageCaption}
          className="mx-auto"
          label="Add image"
          mode="solid"
          onClick={() => {
            if (!chosenImageFile)
              return;

            // add the new image
            setPictures([
              ...pictures,
              {
                caption: imageCaption,
                url: imageUrl,
                name: chosenImageFile.name,
                file: chosenImageFile,
              },
            ]);

            // clear out the form
            setImageCaption("");
            setImageUrl("");
            setChosenImageFile(undefined);
          }}
          type="button"
        />
      </div>
      <div className="space-y-2 px-4">
        <h5 className="text-lg font-semibold text-center">
          Uploaded images
        </h5>

        {/* grid that will contain all the pictures that have been uploaded */}
        <div className="grid grid-cols-5">
          {pictures.map((pic, idx) => (
            <GridPhoto
              key={pic.url}
              picture={pic}
              remove={removeImage.bind(this, idx)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

type GridPhotoProps = {
  picture: Picture;
  remove: () => void;
};

const GridPhoto: FC<GridPhotoProps> = ({
  picture, remove,
}) => {
  return (
    <div
      className={
        "grid-photo relative w-full aspect-square border overflow-hidden " +
        "bg-center bg-cover bg-no-repeat"
      }
      style={{
        backgroundImage: `url(${picture.url})`,
      }}
    >
      <div
        className={
          "grid-photo__overlay absolute top-0 left-0 right-0 bottom-0 " +
          "bg-scrim flex flex-row items-center justify-center"
        }
      >
        <TrashIcon
          className="h-6 w-6 text-white cursor-pointer"
          onClick={remove}
        />
      </div>
    </div>
  );
};
