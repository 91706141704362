import {
  createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword,
} from 'firebase/auth';
import {doc, getDoc, getFirestore, setDoc, updateDoc} from 'firebase/firestore';
import {getMessaging, getToken} from 'firebase/messaging';

import {AdminUser, Picture} from "../@types";
import {deleteFile, uploadPicture} from './cdn';

import {OnCompleteCallback} from '.';

/**
 * Adds a new user to the system and saves all the other additional details to
 * the firestore user collection.
 * @param {AdminUser & {password: string}} user this is the user to be added.
 */
export const addNewUser = async (user: AdminUser & {password: string}): Promise<AdminUser> => {
  const auth = getAuth();
  const db = getFirestore();

  try {
    const userCredentials = await createUserWithEmailAndPassword(
      auth, user.email, user.password,
    );
  
    await setDoc(
      doc(db, "admin", userCredentials.user.uid),
      {...user, uid: userCredentials.user.uid},
    );

    return Promise.resolve({...user, uid: userCredentials.user.uid});
  } catch (err) {
    return Promise.reject(err);
  }
};

export const signInUser = async (
  email: string, password: string,
): Promise<AdminUser> => {
  const auth = getAuth();
  const db = getFirestore();

  try {
    const userCredentials = await signInWithEmailAndPassword(
      auth, email, password,
    );

    if (!userCredentials.user.emailVerified)
      return Promise.reject("Email not verified");
    
    const userSnap = await getDoc(doc(db, "admin", userCredentials.user.uid));
    const user = userSnap.data() as AdminUser;

    if (!user) return Promise.reject("User not found");

    return Promise.resolve(user);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchUser = async (
  uid: string, onComplete: OnCompleteCallback<AdminUser>,
): Promise<void> => {
  const db = getFirestore();

  try {
    const userSnap = await getDoc(doc(db, "admin", uid));

    onComplete(null, userSnap.data() as AdminUser);
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const updateUserDetails = async (
  userId: string, updateableDetails: Partial<AdminUser>,
  onComplete: OnCompleteCallback<AdminUser>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const userDoc = doc(db, "admin", userId);
    updateDoc(userDoc, updateableDetails);

    onComplete(null, (await getDoc(userDoc)).data() as AdminUser);
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const uploadUserPicture = async (
  user: AdminUser, picture: Picture, onComplete: OnCompleteCallback<AdminUser>,
): Promise<void> => {
  const auth = getAuth();
  if (!auth.currentUser) {
    onComplete(new Error("User not signed in"), null);
  
    return;
  };

  const userId = auth.currentUser.uid;

  uploadPicture(`admin/${userId}`, picture, (err, picture) => {
    if (err) {
      onComplete(err, null);

      return;
    }

    if (!picture) {
      onComplete(new Error("Picture not uploaded"), null);

      return;
    }

    // if the picture was uploaded, then update the user's picture in the db
    // while also deleting the previous picture
    const previousUrl = user.avatar?.url;

    // if the previous url is not null, then delete the previous picture
    const newAvatar = picture;

    updateUserDetails(userId, {avatar: newAvatar}, (err, user) => {
      if (err) {
        onComplete(err, null);

        return;
      };

      if (!user) {
        onComplete(new Error("User not found"), null);

        return;
      }

      previousUrl && deleteFile(previousUrl);
      onComplete(null, user);
    });
    // either way the previous picture is deleted
  });
};

export const updateMessagingToken = async (userId: string): Promise<void> => {
  try {
    // make sure that the app is allowed to send notifications
    Notification.requestPermission()
      .then(async (val) => {

        if (val === "denied") {
          console.error("Notifications permission denied");

          return;
        }

        const messaging = getMessaging();

        const messageToken = await getToken(
          messaging,
          {
            vapidKey: "BBNKuN_aNbvXTiBm0cQYalmtp3UTCJpQ7XRBoexLqv9zg4kVniAsRijT02Q7bl1izFTG-933Ov2KQa1pMSdsSkc",
          },
        );

        const db = getFirestore();
        const userDoc = doc(db, "admin", userId);
      
        await updateDoc(userDoc, {token: messageToken} as Partial<AdminUser>);
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
  }
};
