import {FC, useEffect, useState} from "react";
import {MdSave} from "react-icons/md";

import {Picture} from "../@types";
import {OnCompleteCallback} from "../api";

import {AntDesign, ListItem} from ".";
import {Button} from "./button";

type SelectableItem = {
  id: string;
  title: string;
  content: string;
  gallery: Picture[];
  brief?: string;
};

type RecommendedItemsOverlayProps = {
  items: Array<SelectableItem>;
  setItems: (items: Array<unknown>) => void;
  removeOverlay: () => void;
  fetchAllItems: (onComplete: OnCompleteCallback<Array<SelectableItem>>) => void;
};

export const RecommendedItemsOverlay: FC<RecommendedItemsOverlayProps> = ({
  items, setItems, fetchAllItems, removeOverlay,
}) => {
  const [selectedItems, setSelectedItems] = useState<SelectableItem[]>(items);
  const [unselectedItems, setUnselectedItems] = useState<SelectableItem[]>([]);
  
  useEffect(() => {
    fetchAllItems((err, allItems) => {
      if (err) return;
      if (allItems === null) return;

      // filter out the items that have been selected
      const unselectedItems: SelectableItem[] = allItems.filter((item) => {
        return !items.some((selectedItem) => selectedItem.id === item.id);
      });

      setUnselectedItems(unselectedItems);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const unselectItem = (idx: number) => {
    const newSelectedItems = [...selectedItems];
    const splicedItem = newSelectedItems.splice(idx, 1);
    setSelectedItems(newSelectedItems);
    setUnselectedItems([...unselectedItems, ...splicedItem]);
  };

  const selectItem = (idx: number) => {
    const newUnselectedItems = [...unselectedItems];
    const splicedItem = newUnselectedItems.splice(idx, 1);
    setUnselectedItems(newUnselectedItems);
    setSelectedItems([...selectedItems, ...splicedItem]);
  };

  return (
    <div className="no-scrollbar bg-white max-h-[560px] space-y-4 border shadow-lg rounded-2xl overflow-hidden pb-4">
      <div className="sticky top-0 space-y-4">
        {/* header */}
        <div className="flex flex-row items-center justify-between h-12 px-4 border-b">
          <span className="mr-5">
            <h4 className="font-semibold text-xl">
              Recommended Packages
            </h4>
          </span>

          <Button
            label="Save"
            mode="outline"
            type="button"
            Icon={MdSave}
            onClick={() => {
              setItems(selectedItems);
              removeOverlay();
            }}
          />
        </div>

        {/* Recommended Section */}
        <div className="px-4">
          {/* Render the recommended items here */}
          <h6 className="font-semibold text-lg">Recommended Items</h6>

          {/* Render the recommended items here */}
          {selectedItems.length === 0 && (
            <AntDesign.Empty
              description="No recommended items"
            />
          )}

          {selectedItems.map((item, idx) => (
            <AntDesign.Checkbox
              defaultChecked={true}
              key={item.id}
              className="slide-in flex-shrink-0 m-2 flex flex-row items-center"
              onChange={() => unselectItem(idx)}
            >
              <ListItem
                key={item.id}
                className="flex-shrink-0"
                avatar={item.gallery[0]}
                description={item.content}
                title={item.title}
              />
            </AntDesign.Checkbox>
          ))}
        </div>
      </div>

      <hr />

      <div className="px-4">
        {/* Render all the items here */}
        <div>
          <h6 className="font-semibold text-lg">
            Select items to recommend here
          </h6>
          {/* Render the recommended items here */}
          {unselectedItems.length === 0 && (
            <AntDesign.Empty
              description="No items to display"
            />
          )}

          {unselectedItems.map((item, idx) => (
            <AntDesign.Checkbox
              key={item.id}
              className="slide-in flex-shrink-0 m-2 flex flex-row items-center"
              onClick={() => selectItem(idx)}
            >
              <ListItem
                key={item.id}
                className="flex-shrink-0"
                avatar={item.gallery[0]}
                description={item.content}
                title={item.title}
              />
            </AntDesign.Checkbox>
          ))}
        </div>
      </div>
    </div>
  );
};
