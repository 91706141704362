import {FC, ReactNode} from 'react';
import {XIcon} from '@heroicons/react/outline';

type SideSheetProps = {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  onClose?: () => void;
};

export const SideSheet: FC<SideSheetProps> = ({
  title, isOpen, children, className = "", onClose
}) => {
  return (
    <div
      className={
        "no-scrollbar relative flex-shrink-0 flex-grow-0 bg-white border " +
        "shadow-sm overflow-x-hidden overflow-y-scroll pb-4 " +
        className
      }
      style={{
        width: isOpen ? "320px" : "0px",
        transition: "width 0.3s ease-in-out",
      }}
    >
      {/* Header */}
      <div
        className="dissolve h-16 flex flex-row items-center px-4 justify-between opacity-0"
        style={{
          animationDelay: "0.3s",
        }}
      >
        <h6 className="font-semibold text-xl">
          {title}
        </h6>

        {onClose && (
          <XIcon
            className="cursor-pointer h-6 w-6"  
            onClick={onClose}
          />
        )}
      </div>
      <div
        className='px-4 space-y-3'
        style={{
          animationDelay: "0.3s",
        }}
      >
        {children}
      </div>
    </div>
  );
};
