export const DAYS_IN_THE_MONTH = [
  31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
];

const days = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",
];

const months = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
  "Sep", "Oct", "Nov", "Dec",
];

/**
 * Given an index of the day of the week, this function returns the equivalent
 * string of what day of the week that is.
 *
 * @param {number} idx index of the day of the day of the week it is.
 *
 * @returns {string} day of the week in string format
 */
export const getDayOfTheWeek = (idx: number) => days[idx];

/**
 * Given the index of a month in the year, this function returns the equivalent
 * name of that month.
 *
 * @param {number} idx  index of teh month of the year.
 *
 * @returns {string} the month of the year in string format.
 */
export const getMonthOfTheYear = (idx: number) => months[idx];

/**
 * Returns the date in material design acceptable date format.
 *
 * @param {Date} date This is the date obj of the date to be stringified
 * @param {boolean} showHours flag to determine whether the hours should be
 * shown in the date string.
 */
export const getHumanReadableFormat = (date: Date, showHours: boolean = true) => {
  const year = `${
    showHours ?
      "" :
      // only add the comma if the year will be shown
      (new Date().getFullYear() !== date.getFullYear()) ? ", " : ""
  }${
    date.getFullYear() === new Date().getFullYear() ? "" : date.getFullYear()
  }`;

  // hours are only shown if the showHours flag is set to true.
  const hours = showHours ?
    `, ${normalizeInt(date.getHours())}:${normalizeInt(date.getMinutes())}` :
    "";
  return `${
    getMonthOfTheYear(date.getMonth())
  } ${date.getDate()} ${year}${hours}`;
};

const normalizeInt = (int: number) => (int < 10) ? `0${int}`: `${int}`;
