import {FC, MouseEventHandler} from 'react';
import {BsHeart} from 'react-icons/bs';

import {Picture} from '../@types';

import {Chip} from './chip';

type PackageCardProps = {
  title: string;
  subtitle: string;
  description: string;
  cover: Picture;
  saves: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const PackageCard: FC<PackageCardProps> = ({
  title, subtitle, cover, description, saves, onClick,
}) => {
  return (
    <div
      className={
        "package-card relative w-[224px] aspect-square border shadow m-2 " +
        "rounded-2xl overflow-hidden bg-cover bg-center bg-no-repeat " +
        "cursor-pointer"
      }
      style={{
        backgroundImage: `url(${cover.url})`,
      }}
      onClick={onClick}
    >
      {/* First layer */}
      <div
        className={
          "package-card__first-layer relative h-full w-full " +
          "bg-gradient-to-b from-transparent to-white"
        }
      >
        <div className="absolute top-2 right-2 bg-white rounded-md">
          <Chip
            Icon={BsHeart}
            label={`${saves}`}
          />
        </div>

        <div
          className="absolute right-0 bottom-0 left-0 p-4 pb-2 overflow-hidden"
        >
          <p
            className={
              "text-xs text-primary font-semibold text-ellipsis " +
              "overflow-x-hidden whitespace-nowrap"
            }
          >
            {subtitle}
          </p>
          <h6
            className={
              "text-primary text-xl font-semibold text-ellipsis " +
              "overflow-x-hidden whitespace-nowrap"
            }
          >
            {title}
          </h6>
        </div>
      </div>

      {/* Second Layer */}
      <div
        className={
          "package-card__second-layer absolute top-0 right-0 bottom-0 left-0 " +
          "z-10 overflow-hidden"
        }
      >
        <div
          className="dissolve h-1/5 bg-gradient-to-b from-transparent to-black"
        />
        <div
          className="slide-in h-4/5 bg-white overflow-hidden space-y-2 p-4"
        >
          <div className="dissolve overflow-hidden">
            <h6
              className={
                "font-semibold text-xl text-primary text-ellipsis " +
                "whitespace-nowrap overflow-x-hidden"
              }
            >
              {title}
            </h6>
            <p
              className="text-xs text-ellipsis overflow-x-hidden whitespace-nowrap"
            >
              {subtitle}
            </p>
          </div>

          {/* Description is housed here */}
          <p>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};
