import {FC} from 'react';

type TextBubbleProps = {
  align: "left" | "right";
  message: string;
};

export const TextBubble: FC<TextBubbleProps> = ({
  align, message,
}) => {

  return (
    <div className={
        `flex flex-col w-full m-2 bg-white ${
          align === "right" ? "items-end pr-4" : "items-start pl-4"
        }`
      }
    >
      <div
        className={
          `w-full ${
            align === "right" ? "right-4" : "left-4"
          }`
        }
        style={{maxWidth: "60%"}}
      >
        <div
          className={
            `p-4 text-white rounded-2xl ${
              align === "left" ? "rounded-tl-none" : "rounded-tr-none"
            } ${
              align === "right" ? "bg-primary" : "bg-secondary"
            }`
          }
        >
          <p>
            {message}
          </p>
        </div>
      </div>
        <p
          className={
            `mt-1 text-xs opacity-60 ${
              align === "right" ? "mr-4" : "ml-4"
            }`
          }
        >
          20:30 2022-12-12
        </p>
    </div>
  );
};
