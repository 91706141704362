import {useEffect, useState} from 'react';
import Lottie from 'react-lottie';
import {getAuth} from 'firebase/auth';

import {AppContainer, AuthContainer} from './routes';
import {useApp} from './reducers';
import {AppContext} from './context';
import {fetchUser} from './api';
import {Fab, FabExtended, Overlay} from './components';
import {Snackbar} from './components/snackbar';

import authenticatingAnimation from './assets/authenticating.json';
import {ProfilPictureUpload} from './components/profile-picture-upload';


function App() {
  const [state, methods] = useApp();
  // used to look for the initial auth state to prevent premature rendering
  // of the login screen, even though the user is supposed to be logged in
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // register the service worker at the beginning of the mounting
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("Registered service worker");
    }

    // get the user details
    getAuth().onAuthStateChanged((user) => {
      
      if (!user) {
        setLoading(false);
        methods.setUser(null);
        return;
      }

      // TODO: Work on email verification
      // if (!user.emailVerified) {
      //   sendEmailVerification(user, {
      //     url: "https://admin.gpsafaris.com",
      //   });
      //   console.info("Email not verified");
      // }

      fetchUser(user.uid, (err, user) => {
        setLoading(false);
        if (err) {
          console.error(err);
          return;
        }

        methods.setUser(user);

        // if the user's profle picture hast been set, use a snackbar to tell
        // them to finish it off
        if (user && !user.avatar) {
          methods.showSnackbar({
            hide: methods.hideSnackbar,
            isError: false,
            title: "Finish up profile by adding profile picture",
            action: {
              label: "ADD PICTURE",
              onDismiss: () => {
                // show the profile picture upload overlay
                methods.setOverlay(() => (
                  <ProfilPictureUpload
                    user={user}
                    removeOverlay={methods.removeOverlay}
                  />
                ), false);
              },
            },
          })
        }
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* Overlay is cast over everything hence comes first */}
      {state.isOverlay && (
        <Overlay
          isCancellable={state.isCancellable}
          removeOverlay={methods.removeOverlay}
        >
          {state.onOverlay}
        </Overlay>
      )}
      {state.isSnackbarVisible && (
        <Snackbar
          {...state.snackbarConfig}
          hide={methods.hideSnackbar}
        />
      )}
      {!loading && (
        <AppContext.Provider value={{state, methods}}>
          <div
            className="absolute top-0 right-0 bottom-0 left-0 overflow-hidden"
          >
            {state.user && (
              <>
                <AppContainer />
                {state.fabAction && (
                  <div className="absolute bottom-4 right-4 z-50 bg-white rounded-full md:rounded-2xl">
                    <Fab
                      className="md:hidden"
                      Icon={state.fabAction.Icon}
                      action={state.fabAction.action}
                    />
                    <FabExtended
                      className="hidden md:flex"
                      Icon={state.fabAction.Icon}
                      label={state.fabAction.label}
                      action={state.fabAction.action}
                    />
                  </div>
                )}
              </>
            )}

            {!state.user && (
              <AuthContainer />
            )}
          </div>
        </AppContext.Provider>
      )}
      {loading && (
        <div
          className="overflow-hidden w-full flex flex-col items-center justify-center"
          style={{
            height: "100vh",
          }}
        >
          <Lottie
            options={{
              animationData: authenticatingAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={400}
            width={400}
          />

          <div className="mt-4">Authenticating...</div>
        </div>
      )}
    </>
  );
}

export default App;
