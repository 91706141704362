import { CalendarIcon, PencilIcon } from '@heroicons/react/outline';
import {FC, useContext, useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {MdEdit} from 'react-icons/md';
import {useParams, useNavigate} from 'react-router-dom';

import {Package, PackageStats} from '../../@types';
import {fetchAllPackages, fetchItemRecommendations, fetchPackage, fetchPackages, fetchPackageStatistics, setItemRecommendations} from '../../api';
import {AntDesign, ImageCarousel, PackageCard, RecommendedItemsOverlay, StatsCard} from '../../components';
import {AppContext} from '../../context';

export const ViewPackage: FC = () => {
  // Router params
  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();

  // AppContext
  const {
    methods: {
      hideSnackbar, showSnackbar, setHeaderActions, removeHeaderActions,
      setOverlay, removeOverlay,
    },
  } = useContext(AppContext);

  // app state
  const [tourPackage, setTourPackage] = useState<Package>();
  const [recommendedPackages, setRecommendedPackages] = useState<Package[]>([]);

  // package stats
  const [stats, setStats] = useState<PackageStats>({
    likes: [0, 0, 0, 0, 0, 0, 0],
    totalLikes: 0,
    totalViews: 0,
    views: [0, 0, 0, 0, 0, 0, 0],
  });

  // recommended items
  useEffect(() => {
    // fetch recommended packages
    fetchItemRecommendations(`${id}`, "packages", "package", (err, payload) => {
      if (err) {
        showSnackbar({
          isError: true,
          hide: hideSnackbar,
          title: "Unable to fetch recommended packages",
        });
        return;
      }

      if (!payload) return;

      fetchPackages(payload, (err, packages) => {
        if (err) return;
        if (!packages) return;

        console.log(`Fetched ${packages}`);
        setRecommendedPackages(packages);
      });
    });
    
    // fetch the package
    const fetchTourPackage = () => {
      if (!id) return;

      fetchPackage(id, (err, tourPackage) => {
        if (err) {
          console.error(err);
          showSnackbar({
            isError: true,
            hide: hideSnackbar,
            title: "Unable to fetch package details",
            action: {
              label: "Retry",
              onDismiss: fetchTourPackage,
            },
          });
        }

        tourPackage && setTourPackage(tourPackage);

        // if the tour package does not exist, show snackbar about it
        !tourPackage && showSnackbar({
          hide: hideSnackbar,
          isError: true,
          title: "Package does not exist",
          action: {
            label: "Go Back",
            onDismiss: () => navigate("/packages"),
          },
        });
      });

      // fetch the package statistics
      fetchPackageStatistics(id, (err, fetchedStats) => {
        if (err) return;

        if (!fetchedStats) return;

        setStats(fetchedStats);
      });
    };

    // start the tour package fetch process
    fetchTourPackage();

    setHeaderActions([
      null,
      {
        label: "Edit Package",
        Icon: MdEdit,
        onClick: () => {
          navigate(`/packages/edit/${id}`);
        },
      },
    ]);

    return () => {
      removeHeaderActions();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveRecommendations = (recommendations: Array<string>) => {
    // update blog recommendations
    setItemRecommendations(
      `${id}`, "packages", "package", recommendations,
      (err, payload) => {
        if (err) {
          console.error(err);
          showSnackbar({
            isError: true,
            hide: hideSnackbar,
            title: "Unable to save recommendations",
            action: {
              label: "Retry",
              onDismiss: saveRecommendations.bind(this, recommendations),
            },
          });

          return;
        }

        if (!payload) return;

        fetchPackages(payload, (err, packages) => {
          if (err) {
            console.error(err);

            return;
          }

          if (!packages) return;

          showSnackbar({
            hide: hideSnackbar,
            isError: false,
            title: "Recommendations saved",
          });
          // set the populated recommendations
          setRecommendedPackages(packages);
        });
      },
    );
  };

  // this is the loading screnn while the package is being fetched
  if (!tourPackage) {
    return (
      <div
        className="w-full h-full flex flex-row p-4 space-x-4"
        style={{
          height: "calc(100vh - 56px)",
          animationDuration: "1s",
        }}
      >
        <div
          className="flex-1 max-w-[344px] h-full bg-disabled animate-pulse rounded-2xl"
        ></div>
        <div className="flex flex-col flex-1 rounded-2xl space-y-4">
          <div className="w-full h-[192px] bg-disabled animate-pulse rounded-2xl">
          </div>
          <div className="flex-1 w-full flex flex-row space-x-4">
            <div className=" bg-disabled animate-pulse rounded-2xl flex-1">
            </div>
            <div className="flex-shrink-0 flex-grow-0 bg-disabled w-[300px] rounded-2xl"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="w-full h-full flex flex-row"
      style={{
        height: "calc(100vh - 56px)",
      }}
    >
      <div
        className="no-scrollbar flex-1 max-w-[344px] border-r h-full space-y-4 p-4 overflow-scroll"
      >
        <div>
          <h4 className="text-3xl font-semibold">
            Summary Statistics
          </h4>
          <p className="text-subtitle">
            Summary statistics for the package
          </p>
        </div>
        <StatsCard
          suffix="Views"
          title="Total Views"
          value={tourPackage.views.length || 0}
        />

        <StatsCard
          suffix="USD"
          title="Tour package price"
          value={tourPackage.price}
        />

        <StatsCard
          suffix="FT"
          title="Total Follow Throughs"
          value={0}
        />

        <hr />

        <div className="space-y-2">
          <div className="space-y-1">
            <h6 className="font-semibold">
              Graph showing the views for the last 7 days
            </h6>
          </div>

          <Line
            datasetIdKey="label"
            data={{
              labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
              datasets: [
                {
                  label: "",
                  data: stats.views,
                },
              ],
            }}
          />
        </div>
      </div>

      <div className="no-scrollbar flex-1 h-full overflow-scroll p-4 space-y-4">
        {/* Image carousel */}
        <ImageCarousel
          gallery={tourPackage.gallery}
        />

        <div className="space-y-2">
          <h1 className="text-5xl font-semibold">
            {tourPackage.title}
          </h1>
          {/* <h6 className="text-sub text-subtitle">
            This is the Subtitle
          </h6> */}
        </div>

        <div className="flex flex-row space-x-4">
          <div
            className="flex-1"
            dangerouslySetInnerHTML={{
              __html: tourPackage.content,
            }}
          >
          </div>

          {/* Itinerary */}
          <div className="flex-shrink-0 flex-grow-0 space-y-4">
            <div className="space-y-1 flex flex-row items-center">
              <CalendarIcon className="h-6 w-6 mr-4" />
              <h6 className="text-xl font-semibold">Itinerary</h6>
            </div>
            {tourPackage.itinerary.length > 0 && (
              <AntDesign.Timeline mode="left">
                {tourPackage.itinerary.map((event, idx) => (
                  <AntDesign.Timeline.Item
                    key={`package_itinerary_item_${idx}`}
                    label={`Day ${event.dayNumber}`}
                  >
                    {event.title}
                  </AntDesign.Timeline.Item>
                ))}
              </AntDesign.Timeline>
            )}
          </div>
        </div>

        <hr />

        <div className="space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span>
              <h4 className="font-semibold text-xl">
                Recommended Packages
              </h4>
              <p className="font-semibold opacity-75">
                These are the packages that will be recommended as alternatives
                to the user
              </p>
            </span>

            <PencilIcon
              className="h-6 w-6 mr-4 cursor-pointer"
              onClick={() => {
                setOverlay(() => (
                  <RecommendedItemsOverlay
                    fetchAllItems={fetchAllPackages}
                    items={recommendedPackages}
                    setItems={(items) => {
                      // only save the IDs
                      saveRecommendations((items as Package[]).map((item) => item.id));
                    }}
                    removeOverlay={removeOverlay}
                  />
                ), true);
              }}
            />
          </div>

          {/* Render those packages */}
          <div className="flex flex-row">
            {recommendedPackages.map((recommendedPackage, idx) => (
              <PackageCard
                key={recommendedPackage.id}
                cover={recommendedPackage.gallery[0]}
                description={recommendedPackage.content}
                saves={recommendedPackage.saves.length}
                subtitle={`${recommendedPackage.price} USD`}
                title={recommendedPackage.title}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
