import {AdminUser, Role} from "../@types";

// The role of a user is encoded in the user.roles property.
// This propery is a number in which every binary digit represents a role.
// As such these are the flags that represent the roles.
// Blog manager (B), this means that the user can manage blogs
// Package Manager (P) this means that the user can manage packages in the sys
// Communications (C) this means that the user is allowed to communicate with
//                    users of the client website and tend to the client
// B P C
// 0 0 0 = 0 (normal user, only allowed to use the gpsafaris website)
// 0 0 1 = 1 (user only allowed to communicate with clients)
// 0 1 0 = 2 (user only allowed to manage packages)
// 0 1 1 = 3 (packages and communication)
// 1 0 0 = 4 (user only allowed to manage blogs)
// 1 0 1 = 5 (blogs and communication)
// 1 1 0 = 6 (blogs and packages)
// 1 1 1 = 7 (blogs, packages and communication)

export const USER_ROLES: {[Key in Role]: number} = {
  none: 0,
  "customer-relations": 1,
  "package-manager": 2,
  "blog-writer": 4,
};

export const setRoles = (user: AdminUser, roles: Array<Role>) => {
  let newRoles = USER_ROLES.none;

  roles.forEach((role) => {
    newRoles |= USER_ROLES[role];
  });

  user.role = newRoles;
};

export const hasRole = (user: AdminUser, role: Role) => {
  return (user.role & USER_ROLES[role]) === USER_ROLES[role];
}


export const getRoles = (role: number): string[] => {
  const roleMap: {[KEY in Role]: string} = {
    none: "",
    "blog-writer": "Blog Writer",
    "package-manager": "Package Manager",
    "customer-relations": "Customer Relations",
  };

  const roles: string[] = [];

  for (const key in roleMap) {
    if (role & USER_ROLES[key as unknown as Role]) {
      roles.push(roleMap[key as unknown as Role]);
    }
  }

  return roles;
};