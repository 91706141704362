import {
  getFirestore, doc, getDoc, collection, getDocs, query, where,
} from 'firebase/firestore';

import {Reservation, PopulatedReservation, User, Package} from '../@types';

import {OnCompleteCallback} from '.';

export const fetchReservations = async (
  onComplete: OnCompleteCallback<Reservation[]>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const reservationCollection = collection(db, 'reservation');
    const userReservations = await getDocs(reservationCollection);

    onComplete(
      null,
      userReservations.docs.map(doc => doc.data() as Reservation),
    );
  } catch (err) {
    onComplete(err as Error, null);

    return;
  }
};

export const fetchUserReservations = async (
  user: string, onComplete: OnCompleteCallback<Reservation[]>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const reservationCollection = collection(db, "reservation");
    const userReservations = await getDocs(
      query(reservationCollection, where("user", "==", user)),
    );

    onComplete(
      null, userReservations.docs.map(doc => doc.data() as Reservation),
    );
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const fetchReservation = async (
  reservationId: string, onComplete: OnCompleteCallback<Reservation>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const reservationDoc = doc(db, "reservation", reservationId);
    const reservationSnapshot = await getDoc(reservationDoc);

    onComplete(null, reservationSnapshot.data() as Reservation);
  } catch(err) {
    onComplete(err as Error, null);
  }
};

export const populateReservation = async (
  reservation: Reservation, onComplete: OnCompleteCallback<PopulatedReservation>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const userDoc = doc(db, "user", reservation.user);
    const userSnapshot = await getDoc(userDoc);
    const user = userSnapshot.data() as User;

    const packageDoc = doc(db, "package", reservation.package);
    const packageSnapshot = await getDoc(packageDoc);
    const tourPackage = packageSnapshot.data() as Package;

    onComplete(null, {
      ...reservation,
      user,
      package: tourPackage,
    });
  } catch (err) {
    onComplete(err as Error, null);
  }
};
