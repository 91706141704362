import {FC, useContext, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {IconType} from "react-icons";
import {MdEdit} from "react-icons/md";
import {UserRemoveIcon} from "@heroicons/react/solid";
import {getAuth} from "firebase/auth";

import {AppContext} from "../context";
import {RoleManager} from "../util";
import {Button} from "./button";

import {ProfilPictureUpload} from "./profile-picture-upload";

export type HeaderAction = {
  label: string;
  Icon?: IconType;
  onClick: () => void;
  disabled?: boolean;
} | null;

/**
 * The header takes in `actions` from app reducer that can be used to make the
 * header more dynamic in which case page actions can be fitted at the top of
 * the page instead of the user profile picture.
 */
export const Header: FC = () => {
  // context
  const {
    state: {headerActions, user},
    methods: {setOverlay, removeOverlay, setUser},
  } = useContext(AppContext);

  // UI State
  const [profileOpen, setProfileOpen] = useState<boolean>(false);

  // ref
  const modalRef = useOnclickOutside(() => {
    setProfileOpen(false);
  });

  const signOutUser = () => {
    const auth = getAuth();
    auth.signOut();
    setProfileOpen(false);
    setUser(null);
  };

  return (
    <header
      className={
        "z-20 h-[56px] flex flex-row items-center justify-between px-4 " +
        "w-full sticky top-0 bg-black pl-[88px] shadow-lg "
      }
    >
      <h6 className="text-2xl font-semibold text-white">
        gpsafaris
      </h6>

      {/* actions to be shown */}
      {headerActions && (
        <div className="flex flex-row items-center space-x-4">
          {headerActions.map((action, idx) => {
            // if the action is null, just skip it
            if (!action) return null;

            return (
              <button
                key={`header_action_${idx}`}
                disabled={action.disabled}
                className={
                  "h-[36px] flex flex-row items-center px-4 rounded-2xl " +
                  `${
                    action.disabled ?
                    "bg-white bg-opacity-50 text-black cursor-not-allowed" :
                    "bg-white text-black"
                  }`
                }
                type="button"
                onClick={action.onClick}
              >
                {action.Icon && (
                  <action.Icon
                    size={24}
                    className="mr-3"
                  />
                )}
                {action.label}
              </button>
            );
          })}
        </div>
      )}

      {/* Profile picture is shown when there are no actions */}
      {user && !headerActions && (
        <>
          <div
            className={
              "relative h-9 w-9 rounded-full cursor-pointer " +
              "bg-center bg-cover bg-no-repeat"
            }
            style={{
              backgroundImage: user.avatar ? `url(${user.avatar.url})` : "",
            }}
            onClick={() => !profileOpen && setProfileOpen(true)}
          >
          </div>
          {profileOpen && (
            <div
              ref={modalRef}
              className={
                "dissolve absolute top-full right-4 min-w-[144px] mt-1 p-4 " +
                "bg-white border rounded-md shadow-md space-y-4"
              }
            >
              <div
                className={
                  "w-28 h-28 border rounded-full mx-auto relative " +
                  "bg-center bg-cover bg-no-repeat"
                }
                style={{
                  backgroundImage: user.avatar ? `url(${user.avatar.url})` : "",
                }}
              >
                <div
                  className={
                    "absolute bottom-0 right-0 p-2 rounded-full border " +
                    "bg-white cursor-pointer"
                  }
                  onClick={() => {
                    setProfileOpen(false);
                    // open overlay to change profile picture
                    setOverlay(() => (
                      <ProfilPictureUpload
                        user={user}
                        removeOverlay={removeOverlay}
                      />
                    ), false);
                  }}
                >
                  <MdEdit size={18} />
                </div>
              </div>
              <hr />
              <div className="space-y-1">
                <h6 className="font-semibold text-center">
                  {user.name}
                </h6>
                <p className="text-subtitle">
                  {user.email}
                </p>
              </div>
              <hr />
              <ul>
                {/* map out all the user roles */}
                {RoleManager.getRoles(user.role).map((role) => (
                  <li key={role}>{role}</li>
                ))}
              </ul>
              <hr />
              <Button
                className="mx-auto bg-red-600 flex-shrink-0"
                label="Sign out"
                mode="solid"
                type="button"
                Icon={UserRemoveIcon}
                onClick={signOutUser}
              />
            </div>
          )}
        </>
      )}
    </header>
  );
};
