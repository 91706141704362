import {FC, useEffect, useState} from 'react';

import {Picture} from '../@types';

let INTERVAL_HOOK: null | NodeJS.Timer = null;
let IMAGE_COUNTER = 0;

type ImageCarouselProps = {
  gallery: Picture[];
};

export const ImageCarousel: FC<ImageCarouselProps> = ({
  gallery: images,
}) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    INTERVAL_HOOK =  setInterval(() => {
      setCounter((IMAGE_COUNTER + 1) % images.length);
      IMAGE_COUNTER = (IMAGE_COUNTER + 1) % images.length;
    }, 3000);
  
    return () => {
      INTERVAL_HOOK && clearInterval(INTERVAL_HOOK);
      INTERVAL_HOOK = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="relative w-full h-[192px] rounded-2xl overflow-hidden shadow-lg"
    >
      {images.map((image, index) => (
        <div
          key={`image_carousel_${index}`}
          className={`absolute top-0 right-0 bottom-0 left-0 ease-linear ${
              counter === index ? 'opacity-100' : 'opacity-0'
            } bg-center bg-cover bg-no-repeat transition-opacity duration-300`
          }
          style={{
            backgroundImage: `url(${image.url})`,
          }}
        />
      ))}
    </div>
  );
};
