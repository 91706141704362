import {FC, useContext, useEffect, useState} from "react";
import {PopulatedReservation, Reservation} from "../../@types";
import {fetchReservations, populateReservation} from "../../api";

import {AntDesign, Loading, ReservationModal, SideSheet} from "../../components";
import {AppContext} from "../../context";
import {DateUtil} from "../../util";


const filterReservations = (
  calendarMode: "month" | "year", reservations: Reservation[],
  timestamp: number,
): Reservation[] => {
  let LOWER_BOUND = new Date();
  let UPPER_BOUND = new Date();

  if (calendarMode === "month") {
    LOWER_BOUND = new Date(timestamp);
    LOWER_BOUND.setHours(0, 0, 0, 0);

    UPPER_BOUND = new Date(LOWER_BOUND);
    UPPER_BOUND.setHours(23, 59, 59, 999);
  } else {
    LOWER_BOUND = new Date(timestamp);
    LOWER_BOUND.setDate(1);
    LOWER_BOUND.setHours(0, 0, 0, 0);

    UPPER_BOUND = new Date(LOWER_BOUND);
    UPPER_BOUND.setDate(DateUtil.DAYS_IN_THE_MONTH[UPPER_BOUND.getMonth()]);
    UPPER_BOUND.setHours(23, 59, 59, 999);
  }

  const dateReservations = reservations.filter((reservation) => {
    const isBetween = LOWER_BOUND.getTime() <= reservation.timestamp &&
      reservation.timestamp <= UPPER_BOUND.getTime();
    
    return isBetween;
  });

  return dateReservations;
};

export const CalendarRoute: FC = () => {
  const {methods} = useContext(AppContext);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [selectedReservations, setSelectedReservations] = useState<Reservation[]>([]);
  const [calendarMode, setCalendarMode] = useState<"month" | "year">("month");

  const [fetching, setFetching] = useState<boolean>(true);

  useEffect(() => {
    fetchReservations((err, reservations) => {
      if (err || !reservations) {
        setFetching(false);
        if (err) console.error(err);

        methods.showSnackbar({
          hide: methods.hideSnackbar,
          isError: true,
          title: "An error occured fetching reservations",
        });

        return;
      }

      setReservations(reservations);
      setFetching(false);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetching && methods.setOverlay(() => (
      <Loading />
    ), false);

    !fetching && methods.removeOverlay();
  }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className="relative w-full h-full overflow-hidden flex flex-row">
      <div
        className="h-full flex-grow overflow-x-scroll"
      >
        <AntDesign.Calendar
          className="flex-grow h-full w-full"
          dateCellRender={(props) => {
            calendarMode !== "month" && setCalendarMode("month");
            // get the timestamp
            const timestamp = props.valueOf();
            return filterReservations("month", reservations, timestamp)
              .map((reservation) => (
                <CalendarEntry
                  key={`${timestamp}`}
                  reservation={reservation}
                />
              ));
          }}
          monthCellRender={(props) => {
            calendarMode !== "year" && setCalendarMode("year");

            // get the timestamp
            const timestamp = props.valueOf();

            const dateReservations = filterReservations(
              "year", reservations, timestamp,
            );

            return (
              <div className="flex flex-row flex-wrap">
                {dateReservations.map((reservation) => (
                  <CalendarEntry
                    key={reservation.id}
                    className="flex-grow-0 flex-shrink-0"
                    reservation={reservation}
                  />
                ))}
              </div>
            );
          }}
          onSelect={(date) => {
            const selectedReservations = filterReservations(
              calendarMode, reservations, date.valueOf(),
            );

            setSelectedReservations(selectedReservations);
          }}
        />
      </div>
      
      <div
        style={{
          minHeight: "calc(100vh - 56px)",
        }}
      >
        <SideSheet
          isOpen={true}
          title="Reservation"
          className="h-full"
        >
          {selectedReservations.map((reservation) => (
            <CalendarListItem
              key={reservation.id}
              reservation={reservation}
            />
          ))}
        </SideSheet>
      </div>
    </div>
  );
};

type CalendarEntryProps = {
  reservation: Reservation;
  className?: string;
};

const CalendarEntry: FC<CalendarEntryProps> = ({
  reservation, className = "",
}) => {

  return (
    <div
      className={
        "mx-3 px-3 my-1 bg-primary text-white font-semibold rounded-full " +
        "whitespace-nowrap overflow-hidden overflow-ellipsis " +
        className
      }
    >
      {reservation.location}
    </div>
  );
};

type CalendarListItemProps = {
  reservation: Reservation;
};

const CalendarListItem: FC<CalendarListItemProps> = ({
  reservation,
}) => {
  const {methods} = useContext(AppContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [populatedReservation, setPopulatedReservation] = useState<PopulatedReservation>();

  useEffect(() => {
    populateReservation(reservation, (err, payload) => {
      if (err || !payload) {
        if (err) console.error(err);
        setLoading(false);

        return;
      }

      setPopulatedReservation(payload);
      setLoading(false);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        `slide-in flex flex-row border shadow-md ${
          loading || populatedReservation !== undefined ? "h-[72px]" : "h-0"
        } ${
          populatedReservation !== undefined ?
            "cursor-pointer hover:bg-black hover:bg-opacity-5" :
            ""
        } transition-colors duration-300 ease-linear`
      }
      onClick={() => {
        console.log("Setting  overlay");
        // set the overlay to show the reservation in full
        methods.setOverlay(() => (
          <ReservationModal
            reservation={reservation}
          />
        ), true);
      }}
    >
      {/* loading sequence first */}
      {loading && (
        <>
          <div className="h-18 aspect-square bg-gray-400 animate-pulse" />
          <div
            className={
              "flex-grow h-full pl-3 space-y-3 flex flex-col justify-center " +
              "overflow-hidden mr-4"
            }
          >
            <div className="h-5 bg-gray-400 animate-pulse w-full" />
            <div className="h-3 bg-gray-400 animate-pulse w-full" />
          </div>
        </>
      )}

      {!loading && populatedReservation && (
        <>
          <div
            className="h-18 aspect-square bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${
                populatedReservation.package.gallery[0].url
              })`,
            }}
          />

          <div
            className={
              "flex-grow h-full pl-3 space-y-2 flex flex-col justify-center " +
              "overflow-hidden mr-4"
            }
          >
            <h6 className="font-semibold">{populatedReservation.user.name}</h6>
            <p>{populatedReservation.package.title}</p>
          </div>
        </>
      )}
    </div>
  );
};
