import {ChevronDownIcon, ChevronUpIcon, MinusIcon} from '@heroicons/react/outline';
import {FC} from 'react';
import {IconType} from 'react-icons';

import {AntDesign} from '.';

type RankTableProps = {
  headerAction?: {
    Icon: IconType;
    onClick: () => void;
  },
  title: string;
  subtitle: string;
  content: {curr: number; prev?: number; second: string; third: number}[];
  columns: [string, string, string];
};

export const RankTable: FC<RankTableProps> = ({
  title, subtitle, content, columns, headerAction,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-between">
        <div className="space-y-1">
          <h6 className="font-semibold">{title}</h6>
          <p className="text-[11px] text-subtitle">{subtitle}</p>
        </div>

        {headerAction && (
          <headerAction.Icon
            size={24}
            onClick={headerAction.onClick}
          />
        )}
      </div>

      {/* The header for the table */}
      <div className="h-14 flex flex-row items-center border-b">
        <div className="w-1/4 text-center text-sm font-semibold text-primary">
          {columns[0]}
        </div>
        <div className="w-1/2 text-center text-sm font-semibold text-primary">
          {columns[1]}
        </div>
        <div className="w-1/4 text-center text-sm font-semibold text-primary">
          {columns[2]}
        </div>
      </div>

      {content.length === 0 && (
        <AntDesign.Empty
          description="Table data not present"
        />
      )}

      {/* row */}
      {content.map((item, idx) => (
        <div className="h-12 flex flex-row items-center border-b">
          <div
            className="w-1/4 text-center text-sm font-semibold flex flex-row items-center justify-center"
          >
            <h6 className="font-semibold">{item.curr}</h6>
            {(item.curr - (item.prev || 0)) > 0 && (
              <ChevronUpIcon
                className="dissolve ml-3 h-4 w-4 text-green-600"
              />
            )}

            {(item.curr - (item.prev || 0)) < 0 && (
              <ChevronDownIcon
                className="dissolve ml-3 h-4 w-4 text-red-600"
              />
            )}
            {(item.curr - (item.prev || 0)) === 0 && (
              <MinusIcon
                className="dissolve ml-3 h-4 w-4 text-orange-400"
              />
            )}
          </div>
          <div className="w-1/2 text-center text-sm p-4 flex flex-row items-center">
              {item.second}
          </div>
          <div className="w-1/4 text-center text-sm">
            {item.third}
          </div>
        </div>
      ))}
    </div>
  );
};
