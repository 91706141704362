import {FC, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MdAdd, MdEdit} from 'react-icons/md';

import {Blog, BlogStats} from '../../@types';
import {
  fetchAllBlogs, fetchBlogs, fetchBlogStatistics, fetchCategoryRecommendation,
  setCategoryRecommendations,
} from '../../api';
import {ContentCardsContainer, Card, RankTable, RecommendedItemsOverlay} from '../../components';
import {AppContext} from '../../context';

export {ViewBlog} from './view';
export {NewBlog} from './new';
export {EditBlog} from './edit';


export const BlogsContainer: FC = () => {
  const {state, methods: {
    removeFabAction, setFabAction, showSnackbar, hideSnackbar,
    setOverlay, removeOverlay,
  }} = useContext(AppContext);

  // react-routes navigation
  const navigate = useNavigate();

  // Packages
  const [blogs, setBlogs] = useState<Blog[]>([]);

  const [savedRecommendations, setSavedRecommendations] = useState<string[]>([]);
  const [recommendedBlogs, setRecommendedBlogs] = useState<Blog[]>([]);

  const [recommendedBlogsStats, setRecommendedBlogsStats] =
    useState<BlogStats[]>([]);
  
  useEffect(() => {
    setFabAction({
      action: () => {
        navigate("/blogs/new");
      },
      Icon: MdAdd,
      label: "New Blog",
    });

    const fetchBlogs = () => {
      fetchAllBlogs((err, allBlogs) => {
        if (err) {
          showSnackbar({
            isError: true,
            hide: hideSnackbar,
            title: "Unable to fetch blogs",
            action: {
              label: "Retry",
              onDismiss: fetchBlogs,
            },
          });
  
          return;
        }

        allBlogs !== null && setBlogs(allBlogs);
      });
    };

    fetchBlogs();

    return removeFabAction;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCategoryRecommendation("blogs", (err, recommendations) => {
      if (err) {

        return;
      }

      if (!recommendations) return;

      fetchBlogs(recommendations, (err, blogs) => {
        if (err) return;
        if (!blogs) return;

        setRecommendedBlogs(blogs);

        const blogStats: BlogStats[] = [];

        const fetchRecBlogsStats = (idx: number) => {
          if (idx === recommendations.length) {
            setRecommendedBlogsStats(blogStats);

            return;
          }

          fetchBlogStatistics(recommendations[idx], (err, payload) => {
            if (err) {
              console.error(err);
              fetchRecBlogsStats(idx + 1);

              return;
            }

            if (!payload) {
              fetchRecBlogsStats(idx + 1);

              return;
            }

            blogStats.push(payload);
            fetchRecBlogsStats(idx + 1);
          });
        };

        fetchRecBlogsStats(0);
      });
    });
  }, [savedRecommendations]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveRecommendations = (recommendations: string[]) => {
    setCategoryRecommendations(
      "blogs", recommendations, (err, newRecommendations) => {
        if (err) {
          showSnackbar({
            isError: true,
            hide: hideSnackbar,
            title: "Unable to save recommendations",
            action: {
              label: "Retry",
              onDismiss: saveRecommendations.bind(this, recommendations),
            },
          });

          return;
        }

        if (!newRecommendations) return;

        setSavedRecommendations(newRecommendations);
      },
    );
  };

  return (
    <div
      className="relative flex flex-row h-full"
      style={{
        height: "calc(100vh - 56px)",
      }}
    >
      {/* statistics columns */}
      <div
        className="no-scrollbar flex-1 max-w-[500px] flex-shrink-0 p-4 pt-2 space-y-4 overflow-y-scroll"
        style={{height: "calc(100vh - 56px)"}}
      >
        <div>
          <h6 className="font-semibold text-xl">
            Summary Statistics
          </h6>
          <p className="text-[11px] text-subtitle">
            Summary statistics for all the blogs
          </p>
        </div>

        <RankTable
          columns={["Rank",  "Blog Title", "Reads"]}
          title="Top Blogs by Reads"
          subtitle="Last 7 Days"
          content={state.topReadBlogs.map((blog, idx) => ({
            curr: idx + 1,
            prev: isNaN(state.topReadBlogsRankingDiff[idx]) ?
              undefined :
              (idx +  1) - state.topReadBlogsRankingDiff[idx],
            second: blog.title,
            third: blog.views.length,
          }))}
        />

        <RankTable
          columns={["Rank",  "Blog Title", "Engagements"]}
          title="Top Blogs by Engagements"
          subtitle="Last 7 Days"
          content={state.topCommentedBlogs.map((blog, idx) => ({
            curr: idx + 1,
            prev: isNaN(state.topCommentedBlogsRankingDiff[idx]) ?
              undefined :
              (idx +  1) - state.topCommentedBlogsRankingDiff[idx],
            second: blog.title,
            third: blog.comments.length,
          }))}
        />

        <RankTable
          columns={["Rank",  "Blog Title", "Reads"]}
          title="Recommended Blogs"
          subtitle="Last 7 Days"
          headerAction={{
            Icon: MdEdit,
            onClick: () => {
              setOverlay(() => (
                <RecommendedItemsOverlay
                  fetchAllItems={fetchAllBlogs}
                  items={recommendedBlogs}
                  removeOverlay={removeOverlay}
                  setItems={(items) => {
                    saveRecommendations(
                      (items as Array<Blog>).map((item) => item.id),
                    );
                  }}
                />
              ), true);
            },
          }}
          content={recommendedBlogsStats.map((stats, idx) => ({
            curr: idx + 1,
            second: recommendedBlogs[idx].title,
            third: stats.totalReads,
          }))}
        />
      </div>
      <ContentCardsContainer
        title="All Blogs"
        description="All the blogs in the system "
        content={blogs.map((blog) => ({
          description: blog.brief || blog.content,
          title: blog.title,
          image: blog.gallery[0],
          id: blog.id,
        }))}
      >
        {(content) => content.map((blog) => (
          <Card
            key={blog.id}
            description={blog.description}
            image={blog.image}
            title={blog.title}
            onClick={() => {
              navigate(`/blogs/view/${blog.id}`);
            }}
          />
        ))}
      </ContentCardsContainer>
    </div>
  );
};
