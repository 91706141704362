// The /recommendation part in the firestore is used to store the ids of
// packages/blogs with respect to the ID of the item they are supposed to be
// recommended next to.
//
// Data Structure
// At the top level we will store the recommendations that will be shown when
// all the packages/blogs are loaded in the /homepage or /all page
// This includes:
// /recommendtation/blogs
//   this will contains an array with the ids of the blogs that are recommended
//
// /recommendtation/packages/package/{id}
//   this will contain the id of the packages that will be recommended next to
//   the package with the id {id}
//
// /recommendation/packages
//  this will contains an array with the ids of the packages that are recommended
//
// /recommendation/blogs/blog/{id}
//   this will contain the id of the blogs that will be recommended next to
//   the blog with the id {id}

import {getFirestore, getDoc, doc, setDoc} from 'firebase/firestore';

import {OnCompleteCallback} from '.';

type RecommendationCategories = "blogs" | "packages";

export type Recommendation = {
  id: string;
  recommendations: string[];
};

export const fetchCategoryRecommendation = async (
  category: RecommendationCategories,
  onComplete: OnCompleteCallback<Array<string>>,
): Promise<void> => {
  try {
    const db = getFirestore();

    const recommendationsDoc = doc(db, "recommendation", category);
    const recommendationSnapshot = await getDoc(recommendationsDoc);

    if (!recommendationSnapshot.exists()) {
      onComplete(null, []);

      return;
    }
    
    const recommendations = recommendationSnapshot.data() as Recommendation;
    onComplete(null, recommendations.recommendations);
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const fetchItemRecommendations = async (
  id: string, category: "blogs" | "packages", item: "blog" | "package",
  onComplete: OnCompleteCallback<string[]>,
): Promise<void> => {
  try {
    const db = getFirestore();

    const recommendationsDoc = doc(db, "recommendation", category, item, id);
    const recommendationSnapshot = await getDoc(recommendationsDoc);

    if (!recommendationSnapshot.exists()) {
      onComplete(null, []);

      return;
    }

    const recommendations = recommendationSnapshot.data() as Recommendation;
    onComplete(null, recommendations.recommendations);
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const setCategoryRecommendations = async (
  category: RecommendationCategories, recommendations: string[],
  onComplete: OnCompleteCallback<string[]>,
): Promise<void> => {
  try {
    const db = getFirestore();

    const recommendationsDoc = doc(db, "recommendation", category);
    await setDoc(recommendationsDoc, {
      id: category,
      recommendations,
    });

    fetchCategoryRecommendation(category, onComplete);
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const setItemRecommendations = async (
  id: string, category: RecommendationCategories, item: "blog" | "package",
  recommendations: string[], onComplete: OnCompleteCallback<string[]>,
): Promise<void> => {
  try {
    const db = getFirestore();

    const recommendationDoc = doc(db, "recommendation", category, item, id);
    await setDoc(recommendationDoc, {
      id,
      recommendations,
    } as Recommendation);

    // fetch the set of recommendations for the item
    await fetchItemRecommendations(id, category, item, onComplete);
  } catch (err) {
    onComplete(err as Error, null);
  }
};
