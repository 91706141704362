import {FC, useState, useContext, createRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {HashtagIcon, PencilAltIcon} from '@heroicons/react/outline';
import {EditorState} from 'draft-js';

import {
  ImageCollectionOverview, ImageUploadModal, Input as TextInput, InputChip,
  ContentCreator, Uploading,
} from '../../components';
import {AppContext} from '../../context';
import {Blog, Picture} from '../../@types';
import { addNewBlog } from '../../api';

export const NewBlog: FC = () => {
  const navigate = useNavigate();

  // Context
  const {methods} = useContext(AppContext);
  // ContentCreator ref
  const [contentCreatorRef, ] = useState(createRef<ContentCreator>());
  // Form state
  const [title, setTitle] = useState<string>("");
  const [tag, setTag] = useState<string>("");
  const [gallery, setGallery] = useState<Picture[]>([]);

  // content creator state
  const [editorState, ] =
    useState<EditorState>(EditorState.createEmpty());

  // all tags
  const [tags, setTags] = useState<string[]>([]);

  const removeTags = (idx: number) => {
    const newTags = [...tags];
    newTags.splice(idx, 1);
    setTags(newTags);
  };

  // form input validation
  const titleValid = title.length > 0 && /[A-Za-z0-9]+/g.test(title) === true;
  const galleryValid = gallery.length > 0;
  const tagsValid = tags.length > 0;

  const createPackage = () => {
    if (!contentCreatorRef.current) return;

    // add an upload overlay, that will either resolve with an error, or
    // navigating to all packages with a snackbar about the successfull upload
    methods.setOverlay(() => (
      <Uploading />
    ), false);


    const newBlog: Blog = {
      // NOTE: This will be replaed with a generated id when the package is saved
      id: "",
      title,
      content: contentCreatorRef.current.getHTML(),
      gallery,
      tags,
      createdAt: Date.now(),
      lastEdited: Date.now(),
      comments: [],
      likes: [],
      views: [],
    };

    addNewBlog(newBlog, (err, res) => {
      methods.removeOverlay();

      if (err) {
        methods.showSnackbar({
          hide: methods.hideSnackbar,
          isError: true,
          title: "Error posting blog",
        });

        return;
      }

      navigate("/blogs", {replace: true});

      // show a snackbar about the successfull upload
      methods.showSnackbar({
        hide: methods.hideSnackbar,
        isError: false,
        title: "Blog posted successfully",
      });
    });
  };

  // once the component is mounted, we want create the header actions, and also
  // watch for the inputs to know when to enable and disable these components
  useEffect(() => {
    // form input validation
    const titleValid = title.length > 0 && /[A-Za-z0-9]+/g.test(title) === true;
    const galleryValid = gallery.length > 0;
    const tagsValid = tags.length > 0;

    const disabled = !titleValid || !galleryValid || !tagsValid;

    // TODO: Finalise with the api calls to create/draft the package
    methods.setHeaderActions([
      null,
      {
        label: "Publish",
        onClick: () => createPackage(),
        disabled,
      },
    ]);

    return () => {
      methods.removeHeaderActions();
    };
  }, [title, tags, gallery]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full h-full flex flex-row z-0">
      {/* We make this container sticky relative to the main app container */}
      <div
        className={
          "no-scrollbar w-96 border-r sticky top-[56px] space-y-4 p-4 " +
          "overflow-y-scroll flex-shrink-0"
        }
        style={{height: "calc(100vh - 56px)"}}
      >
        <div className="space-y-1">
          <h4 className="text-3xl font-semibold">Miscalleneous</h4>
          <p className="text-subtitle">
            This section contains additional details about the blog
          </p>
        </div>
        <TextInput
          helper="This is the title the user will see"
          inputLabel="Blog Package Title"
          id="blog-title"
          name="blog_title"
          type="text"
          value={title}
          error={!titleValid ? "Title is required" : ""}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />

        {/* gallery images */}
        <div className="space-y-2">
          <span className="flex flex-row items-center justify-between">
            <h4 className="text-xl font-semibold">Gallery</h4>
            <PencilAltIcon
              className="h-6 w-6 text-black cursor-pointer"
              onClick={() => {
                // create an overlay that will allow the addition of new
                // pictures
                methods.setOverlay(() => (
                  <ImageUploadModal
                    images={gallery}
                    title="Upload new images"
                    removeOverlay={methods.removeOverlay}
                    onSave={(newPictures) => {
                      setGallery(newPictures);
                    }}
                  />
                ), false);
              }}
            />
          </span>

          <ImageCollectionOverview
            pictures={gallery}
          />

          {/* Handy message to the user that the images are not filled in */}
          {!galleryValid && (
            <div className="space-y-4">
              <p className="text-center opacity-60">
              There are no images added to the blog gallery right now, please
              add at least one.
              </p>
              <hr />
            </div>
          )}
        </div>

        <div>
          <span className="flex flex-row items-center">
            <HashtagIcon
              className="h-6 w-6 text-black mr-4"
            />
            <div>
              <h6 className="text-lg font-semibold">Searchable Tags</h6>
              <p className="text-[11px] opacity-70">
                Add at least one. This are important for SEO
              </p>
            </div>
          </span>
          {/* 
            This is a make shift solution to make sure we remove the need for
            a button to submit, as such we wrap the input in a form and use
            the onSubmit event to add the tag to the tags array and also prevent
            the default behaviour to make sure the page doesn't refresh
           */}
          <form
            onSubmit={(e) => {
              e.preventDefault()
              setTags([...tags, tag]);
              setTag("");
            }}
          >
            <TextInput
              className="my-2"
              value={tag}
              type="text"
              helper="Add the tag name here"
              id="package-tag"
              name="package_tag"
              inputLabel="tag"
              onChange={(e) => {
                setTag(e.target.value);
              }}
            />
          </form>
          {/* render all the tags as InputChips */}
          <div className="flex flex-row flex-wrap">
            {tags.map((tag, idx) => (
              <InputChip
                className="flex-shrink-0 m-2"
                key={`hashtag_${idx}`}
                label={tag}
                onRemove={removeTags.bind(this, idx)}
              />
            ))}
          </div>
          {!tagsValid && (
            <div className="space-y-4">
              <p className="text-center opacity-60">
              There are no tags added to the package right now, please
              add at least one to improve search engin optimization
              </p>
              <hr />
            </div>
          )}
        </div>
      </div>
      <div
        className="bg-black bg-opacity-5"
        style={{minHeight: "calc(100vh - 56px)"}}
      >
        <ContentCreator
          ref={contentCreatorRef}
          initialEditorState={editorState}
        />
      </div>
    </div>
  );
};
