import {getDatabase, get, ref, update, push} from 'firebase/database';

import {Chat, Message, MessageThread, PopulatedChat} from '../@types';

import {OnCompleteCallback} from '.';
import {fetchUser} from './user';


export const populateChatThreads = async (
  chats: Chat, onComplete: OnCompleteCallback<Array<PopulatedChat>>,
): Promise<void> => {
  try {
    const populatdChats: PopulatedChat[] = [];
    let populatedChatCount = 0;

    const chatsArray: Array<Chat[string] & { id: string }> = [];

    for (let key in chats) {
      chatsArray.push({ ...chats[key], id: key });
    }

    const populateChat = () => {
      if (populatedChatCount === chatsArray.length) {
        onComplete(null, populatdChats);

        return;
      }

      const currentChat = chatsArray[populatedChatCount];

      fetchUser(currentChat.id, async (err, user) => {
        if (err) {
          onComplete(err, null);

          return;
        }

        if (!user) {
          onComplete(new Error('User not found'), null);

          return;
        }

        const populatedChat: PopulatedChat = {
          ...currentChat,
          user,
          mostRecentMessage: currentChat.mostRecentMessage,
        };

        populatdChats.push(populatedChat);

        // increment counter and repeat
        populatedChatCount++;
        populateChat();
      });
    }

    populateChat()
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const markAsRead = async (
  userId: string,
): Promise<boolean> => {
  try {
    const db = getDatabase();
    const messagesRef = ref(db, `messages/${userId}/from`);

    const allMessagesSnapshot = await get(messagesRef);

    if (!allMessagesSnapshot) {
      return false;
    }

    const allMessages = allMessagesSnapshot.val() as MessageThread["to"];

    for (const message in allMessages) {
      allMessages[message].status = "read";
    }

    update(messagesRef, allMessages);

    return true;
  } catch (err) {
    return false;
  }
};

export const sendMessage = async (
  userId: string, message: Message,
): Promise<boolean> => {
  try {
    const db = getDatabase();
    const messagesRef = ref(db, `messages/${userId}/to`);

    await push(messagesRef, message);

    return true;
  } catch (err) {
    return false;
  }
};
