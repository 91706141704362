import {FC, useEffect, useState} from 'react';

import {PopulatedReservation, Reservation} from '../@types';
import {populateReservation} from '../api';
import {DateUtil} from '../util';

type ReservationModalProps = {
  reservation: Reservation;
};

export const ReservationModal: FC<ReservationModalProps> = ({
  reservation,
}) => {
  const [populatedReservation, setPopulatedReservation] =
    useState<PopulatedReservation>();
  
  useEffect(() => {
    populateReservation(reservation, (err, payload) => {
      if (err || !payload) {
        if (err) console.error(err);

        return;
      }

      setPopulatedReservation(payload);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fromDate = new Date(reservation.date.from);
  const toDate = new Date(reservation.date.to);

  return (
    <div
      className={
        "w-fit max-w-[560px] max-h-[560px] mx-auto flex flex-col " +
        "items-center space-y-4 pt-4 sm:flex-shrink-0 sm:flex-grow-0 " +
        "bg-white px-4 border shadow-lg rounded-2xl overflow-hidden"
      }
    >
      <h6 className="text-2xl font-semibold">
        GPSafaris Receipt
      </h6>
      <h6 className="font-semibold text-subtitle">
        Receipt ID (#{reservation?.id})
      </h6>

      <hr />

      <div className="h-12 border-b w-full grid grid-cols-2">
        <h6 className="text-start font-semibold">Package</h6>
        <p className="text-end">
          {populatedReservation?.package?.title}
        </p>
      </div>

      <div className="h-12 border-b w-full grid grid-cols-3">
        <h6 className="text-start font-semibold">Date</h6>
        <p className="text-end">
          {DateUtil.getHumanReadableFormat(fromDate, false)}
        </p>
        <p className="text-end">
          {DateUtil.getHumanReadableFormat(toDate, false)}
        </p>
      </div>

      <div className="h-12 border-b w-full grid grid-cols-3">
        <h6 className="text-start font-semibold">People</h6>
        <p className="text-end">
          {`${reservation.people.adults} adults`}
        </p>
        <p className="text-end">
          {`${reservation.people.children} children`}
        </p>
      </div>

      <div className="h-12 border-b w-full grid grid-cols-2">
        <h6 className="text-start font-semibold">Location</h6>
        <p className="text-end">{reservation.location}</p>
      </div>

      <div className="h-12 border-b w-full grid grid-cols-2">
        <h6 className="text-start font-semibold">Price</h6>
        <p className="text-end">
          {`$${reservation.price}`}
        </p>
      </div>

      <div className="h-12 border-b w-full grid grid-cols-3">
        <h6 className="text-start font-semibold">Payment mode</h6>
        <p className="text-end">
          {reservation.mode === "paypal" ? "PayPal" : "M-Pesa"}
        </p>
        <p className="text-end">{reservation?.transaction_id}</p>
      </div>

      <div className="h-12 border-b w-full grid grid-cols-2">
        <h6 className="text-start font-semibold">Paid on</h6>
        <p className="text-end">
          {DateUtil.getHumanReadableFormat(new Date(reservation.timestamp))}
        </p>
      </div>

      <div className="h-12 border-b w-full grid grid-cols-2">
        <h6 className="text-start font-semibold">Reservation Name</h6>
        <p className="text-end">
          {populatedReservation?.user?.name}
        </p>
      </div>
    </div>
  );
};
