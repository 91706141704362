import {
  CSSProperties, FC, FormEventHandler, HTMLInputTypeAttribute,
} from 'react';

import {InputProps} from '.';

type TextInputProps = InputProps & {
  type: HTMLInputTypeAttribute;
  onSubmit?: FormEventHandler<HTMLInputElement>;
  style?: CSSProperties;
};

export const TextInput: FC<TextInputProps> = ({
  helper, id, name, inputLabel, error, value, disabled, className, type,
  style = {}, onBlur, onChange, onSubmit,
}) => {
  const isError = error && error.length > 0;

  return (
    <div className={className ? className : ""}>
      <div
        className="text-input-container transition-colors duration-200"
        style={ isError ? {borderColor: "red", ...style} : {...style} }
      >
        <input
          autoComplete="off"
          className="text-input"
          id={id}
          name={name}
          placeholder=" "
          value={value}
          onChange={onChange}
          disabled={disabled ? disabled : false}
          onBlur={onBlur}
          type={type}
          onSubmit={onSubmit}
        />
        <label htmlFor={id} className="text-input-label">{inputLabel}</label>
      </div>
      <div className="transition-all duration-150 ease-linear">
        {error && (
          <p className="text-xs pl-2 text-red-500">{error}</p>
        )}
        {!error && value === "" && (
          <p className="text-xs pl-2">
            {helper}
          </p>
        )}
      </div>
    </div>
  );
};
