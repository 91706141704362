import {Component} from "react";
import {convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

type ContentCreatorProps = {
  initialEditorState: EditorState;
};

type ContentCreatorState = {
  editorState: EditorState;
};

/**
 * This is a reusable component that can be used to create content, the content
 * can is initially got from the props (passed onto it by the parent component)
 * and then managed loally by the component. This conent is only exported using
 * the method `exportContent` which can be called by the parent component using
 * a ref to the component.
 *
 * @note The conocious choice was made to use a class component in order to make it
 * easier to use ref to access and save the editor state as raw JSON content
 * when saving the package.
 */
export class ContentCreator extends Component<ContentCreatorProps, ContentCreatorState> {
  state = {
    editorState: this.props.initialEditorState,
  };

  setEditorState = (editorState: EditorState) => {
    this.setState({editorState});
  };

  getHTML = () => {
    return draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
  }

  render() {
    return (
      <div className="h-full">
        <Editor
          toolbarClassName="sticky top-[56px] z-10"
          editorClassName="bg-white shadow-md m-4 p-4 max-w-[768px] mx-auto overflow-visible"
          editorState={this.state.editorState}
          onEditorStateChange={this.setEditorState}
        />
      </div>
    );
  }
};
