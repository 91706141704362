import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import {FC, useState} from 'react';

import {Picture} from '../@types';

type ImageCarouselOverlayProps = {
  pictures: Picture[];
  removeOverlay: () => void
};

export const ImageCarouselOverlay: FC<ImageCarouselOverlayProps> = ({
  pictures, removeOverlay,
}) => {
  const [counter, setCounter] = useState<number>(0);

  return (
    <div className="w-96 h-[32rem] shadow-lg bg-white">
      <div
        className="relative h-96 border p-2 flex flex-col justify-center"
      >
        {/*
          these buttons will be used to navigate back and forth betwen pictures
        */}
        <div className="flex flex-row items-center justify-between">
          <div
            className="p-2 bg-white rounded-full z-50 shadow-md cursor-pointer"
            onClick={() => {
              setCounter(((counter + (pictures.length)) - 1) % pictures.length);
            }}
          >
            <ChevronLeftIcon
              className="h-4 w-4"
            />
          </div>

          <div
            className="p-2 bg-white rounded-full z-50 shadow-md cursor-pointer"
            onClick={() => {
              setCounter((counter + 1) % pictures.length);
            }}
          >
            <ChevronRightIcon
              className="h-4 w-4"
            />
          </div>
        </div>
        {/* render all the pictures and then just mess with the opacity */}
        {pictures.map((picture, idx) => (
          <div
            key={picture.url}
            className={
              "absolute top-0 right-0 bottom-0 left-0 w-full h-full " +
              "bg-cover bg-center bg-no-repeat transition-[opacity] " +
              "duration-200 ease-linear " +
              `${counter === idx ? "opacity-100" : "opacity-0"}`
            }
            style={{
              backgroundImage: `url(${picture.url})`,
            }}
          ></div>
        ))}
      </div>
      <div className="h-8 p-4">
        <p>{pictures[counter].caption}</p>
      </div>
    </div>
  );
};
