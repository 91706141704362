import {FC, ReactElement, useEffect} from 'react';
import {XIcon} from '@heroicons/react/outline';
import useOnclickOutside from 'react-cool-onclickoutside';

type OverlayProps = {
  isCancellable: boolean;
  removeOverlay: () => void;
  children: () => (ReactElement<{removeOverlay: () => void}> | null);
};

export const Overlay: FC<OverlayProps> = ({
  isCancellable, removeOverlay, children,
}) => {
  // refs
  const contentContainer = useOnclickOutside(
    () => isCancellable && removeOverlay(),
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={
        "absolute top-0 right-0 bottom-0 left-0 flex flex-row items-center " +
        "justify-center bg-scrim z-50"
      }
    >
      {/* create the cancellable element if the overlay is cancellable */}
      {isCancellable && (
        <XIcon
          color="white"
          className="absolute h-6 w-6 top-0 right-0 m-4 cursor-pointer"
          onClick={() => {
            removeOverlay();
          }}
        />
      )}
      {/*
        we wrap the children in a div to enable for onClickOutside behaviour
        if the overlay is cancellable
      */}
      <div ref={contentContainer}>
        {children()}
      </div>
    </div>
  );
};
