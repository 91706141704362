import {doc, getDoc, getFirestore} from 'firebase/firestore';

import {User} from '../@types/user';

import {OnCompleteCallback} from '.';

export const fetchUser = async (
  userId: string, onComplete: OnCompleteCallback<User>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const userDoc = doc(db, "user", userId);
    const user = await getDoc(userDoc);

    const userData = user.data() as User;

    onComplete(null, userData);
  } catch (err) {
    onComplete(err as Error, null);
  }
};
