import {ReactElement, useReducer} from 'react';

import {AdminUser, Blog, Package, WeekSummary} from '../@types';
import {SnackbarProps, IFAB} from '../components';
import {HeaderAction} from '../components/header';

export type AppState = {
  user: AdminUser | null;
  isOverlay: boolean;
  isCancellable: boolean;
  isSnackbarVisible: boolean;
  snackbarConfig: SnackbarProps;
  onOverlay: () => (ReactElement<{removeOverlay: () => void}> | null);
  headerActions?: [HeaderAction, HeaderAction];
  fabAction?: IFAB;
  // app summary
  summary: WeekSummary;
  topLikedPackages: Package[];
  topLikedPackagesRankingDiff: number[];
  topViewedPackages: Package[];
  topViewedPackagesRankingDiff: number[];
  topReadBlogs: Blog[];
  topReadBlogsRankingDiff: number[];
  topCommentedBlogs: Blog[];
  topCommentedBlogsRankingDiff: number[];
};

const DEFAULT_SNACKBAR_CONFIG: SnackbarProps = {
  hide: () => {},
  isError: false,
  title: "",
};
export const INITIAL_STATE: AppState = {
  user: null,
  isCancellable: true,
  isOverlay: false,
  onOverlay: () => null,
  isSnackbarVisible: false,
  snackbarConfig: DEFAULT_SNACKBAR_CONFIG,
  summary: {
    blogs: {
      comments: [0, 0, 0, 0, 0, 0, 0],
      reads: [0, 0, 0, 0, 0, 0, 0],
      reads_rank: [],
      comments_rank: [],
      total_comments: 0,
      total_reads: 0,
    },
    packages: {
      likes: [0, 0, 0, 0, 0, 0, 0],
      views: [0, 0, 0, 0, 0, 0, 0],
      likes_rank: [],
      total_likes: 0,
      total_views: 0,
      views_rank: [],
    },
  },
  topCommentedBlogs: [],
  topCommentedBlogsRankingDiff: [],
  topLikedPackages: [],
  topLikedPackagesRankingDiff: [],
  topReadBlogs: [],
  topReadBlogsRankingDiff: [],
  topViewedPackages: [],
  topViewedPackagesRankingDiff: [],
};

function appReducer(state: AppState, action: any): AppState {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case "SET_OVERLAY":
      return {
        ...state,
        isOverlay: true,
        onOverlay: action.payload.onOverlay as AppState["onOverlay"],
        isCancellable: action.payload.isCancellable as boolean,
      };
    case "REMOVE_OVERLAY":
      return {
        ...state,
        isOverlay: false,
        onOverlay: () => null,
        isCancellable: true,
      };
    case "SHOW_SNACKBAR":
      return {
        ...state,
        isSnackbarVisible: true,
        snackbarConfig: action.payload as SnackbarProps,
      };
    case "HIDE_SNACKBAR":
      return {
        ...state,
        isSnackbarVisible: false,
        snackbarConfig: DEFAULT_SNACKBAR_CONFIG,
      };
    case "SET_HEADER_ACTIONS":
      return {
        ...state,
        headerActions: action.payload,
      };
    case "REMOVE_HEADER_ACTIONS":
      return {
        ...state,
        headerActions: undefined,
      };
    case "SET_FAB_ACTION":
      return {
        ...state,
        fabAction: action.payload,
      };
    case "REMOVE_FAB_ACTION":
      return {
        ...state,
        fabAction: undefined,
      };
    
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload,
      };
    case "SET_TOP_LIKED_PACKAGES":
      return {
        ...state,
        topLikedPackages: action.payload,
      };
    case "SET_TOP_LIKED_PACKAGES_RANKING_DIFF":
      return {
        ...state,
        topLikedPackagesRankingDiff: action.payload,
      };
    case "SET_TOP_VIEWED_PACKAGES":
      return {
        ...state,
        topViewedPackages: action.payload,
      };
    case "SET_TOP_VIEWED_PACKAGES_RANKING_DIFF":
      return {
        ...state,
        topViewedPackagesRankingDiff: action.payload,
      };
    case "SET_TOP_READ_BLOGS":
      return {
        ...state,
        topReadBlogs: action.payload,
      };
    case "SET_TOP_READ_BLOGS_RANKING_DIFF":
      return {
        ...state,
        topReadBlogsRankingDiff: action.payload,
      };
    case "SET_TOP_COMMENTED_BLOGS":
      return {
        ...state,
        topCommentedBlogs: action.payload,
      };
    case "SET_TOP_COMMENTED_BLOGS_RANKING_DIFF":
      return {
        ...state,
        topCommentedBlogsRankingDiff: action.payload,
      };
    default:
      return state;
  }
}

export type AppReducerFunctions = {
  setUser: (user: AdminUser | null) => void;
  setOverlay: (onOverlay: AppState["onOverlay"], isCancellable: boolean) => void;
  removeOverlay: () => void;
  showSnackbar: (config: SnackbarProps) => void;
  hideSnackbar: () => void;
  setHeaderActions: (actions: [HeaderAction, HeaderAction]) => void;
  removeHeaderActions: () => void;
  setFabAction: (action: IFAB) => void;
  removeFabAction: () => void;
  setSummary: (summary: WeekSummary) => void;
  setTopLikedPackages: (packages: Package[]) => void;
  setTopLikedPackagesRankingDiff: (rankingDiff: number[]) => void;
  setTopViewedPackages: (packages: Package[]) => void;
  setTopViewedPackagesRankingDiff: (rankingDiff: number[]) => void;
  setTopReadBlogs: (blogs: Blog[]) => void;
  setTopReadBlogsRankingDiff: (rankingDiff: number[]) => void;
  setTopCommentedBlogs: (blogs: Blog[]) => void;
  setTopCommentedBlogsRankingDiff: (rankingDiff: number[]) => void;
};

export const useApp = (): [AppState, AppReducerFunctions] => {
  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);

  const setUser = (user: AdminUser | null) => {
    dispatch({
      type: 'SET_USER',
      payload: user,
    });
  };

  const setOverlay = (
    onOverlay: AppState["onOverlay"], isCancellable: boolean,
  ): void => {
    dispatch({
      type: 'SET_OVERLAY',
      payload: {
        isOverlay: true,
        onOverlay,
        isCancellable,
      },
    });
  };

  const removeOverlay = () => {
    dispatch({
      type: "REMOVE_OVERLAY",
      payload: null,
    });
  };

  const showSnackbar = (config: SnackbarProps) => {
    dispatch({
      type: "SHOW_SNACKBAR",
      payload: config,
    });
  };

  const hideSnackbar = () => {
    dispatch({
      type: "HIDE_SNACKBAR",
      payload: null,
    });
  };

  const setHeaderActions = (headerActions: [HeaderAction, HeaderAction]) => {
    dispatch({
      type: "SET_HEADER_ACTIONS",
      payload: headerActions,
    });
  };

  const removeHeaderActions = () => {
    dispatch({
      type: "REMOVE_HEADER_ACTIONS",
      payload: null,
    });
  };

  const setFabAction = (fabAction: IFAB) => {
    dispatch({
      type: "SET_FAB_ACTION",
      payload: fabAction,
    });
  };

  const removeFabAction = () => {
    dispatch({
      type: "REMOVE_FAB_ACTION",
      payload: null,
    });
  };

  const setSummary = (summary: WeekSummary) => {
    dispatch({
      type: "SET_SUMMARY",
      payload: summary,
    });
  };

  const setTopLikedPackages = (packages: Package[]) => {
    dispatch({
      type: "SET_TOP_LIKED_PACKAGES",
      payload: packages,
    });
  };

  const setTopLikedPackagesRankingDiff = (rankingDiff: number[]) => {
    dispatch({
      type: "SET_TOP_LIKED_PACKAGES_RANKING_DIFF",
      payload: rankingDiff,
    });
  };

  const setTopViewedPackages = (packages: Package[]) => {
    dispatch({
      type: "SET_TOP_VIEWED_PACKAGES",
      payload: packages,
    });
  };

  const setTopViewedPackagesRankingDiff = (rankingDiff: number[]) => {
    dispatch({
      type: "SET_TOP_VIEWED_PACKAGES_RANKING_DIFF",
      payload: rankingDiff,
    });
  };

  const setTopReadBlogs = (blogs: Blog[]) => {
    dispatch({
      type: "SET_TOP_READ_BLOGS",
      payload: blogs,
    });
  };

  const setTopReadBlogsRankingDiff = (rankingDiff: number[]) => {
    dispatch({
      type: "SET_TOP_READ_BLOGS_RANKING_DIFF",
      payload: rankingDiff,
    });
  };

  const setTopCommentedBlogs = (blogs: Blog[]) => {
    dispatch({
      type: "SET_TOP_COMMENTED_BLOGS",
      payload: blogs,
    });
  };

  const setTopCommentedBlogsRankingDiff = (rankingDiff: number[]) => {
    dispatch({
      type: "SET_TOP_COMMENTED_BLOGS_RANKING_DIFF",
      payload: rankingDiff,
    });
  };

  return [
    state,
    {
      setUser, setOverlay, removeOverlay, showSnackbar, hideSnackbar,
      removeHeaderActions, setHeaderActions, setFabAction, removeFabAction,
      setSummary, setTopCommentedBlogs, setTopCommentedBlogsRankingDiff,
      setTopLikedPackages, setTopLikedPackagesRankingDiff, setTopReadBlogs,
      setTopReadBlogsRankingDiff, setTopViewedPackages,
      setTopViewedPackagesRankingDiff,
    },
  ];
}
