import { PencilIcon } from '@heroicons/react/outline';
import {FC, useContext, useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {MdEdit} from 'react-icons/md';
import {useParams, useNavigate} from 'react-router-dom';

import {Blog, BlogStats} from '../../@types';
import {fetchAllBlogs, fetchBlog, fetchBlogs, fetchBlogStatistics, fetchItemRecommendations, setItemRecommendations} from '../../api';
import {ImageCarousel, PackageCard, RecommendedItemsOverlay, StatsCard} from '../../components';
import {AppContext} from '../../context';

export const ViewBlog: FC = () => {
  // Router params
  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();

  // AppContext
  const {
    methods: {
      setHeaderActions, removeHeaderActions, showSnackbar, hideSnackbar,
      setOverlay, removeOverlay,
    },
  } = useContext(AppContext);

  const [blog, setBlog] = useState<Blog>();
  const [recommendedBlogs, setRecommendedBlogs] = useState<Blog[]>([]);

  // blog summary stats
  const [stats, setStats] = useState<BlogStats>({
    comments: [0, 0, 0, 0, 0, 0, 0],
    reads: [0, 0, 0, 0, 0, 0, 0],
    totalComments: 0,
    totalReads: 0,
  });

  useEffect(() => {
    if (!id) return;

    setHeaderActions([
      null,
      {
        label: "Edit Blog",
        Icon: MdEdit,
        onClick: () => {
          navigate(`/blogs/edit/${id}`);
        },
      },
    ]);

    const fetchBlogContent = () => {
      fetchBlog(id, (err, fetchedBlog) => {
        if (err) {
          showSnackbar({
            isError: true,
            hide: hideSnackbar,
            title: "Unable to fetch blog",
            action: {
              label: "Retry",
              onDismiss: fetchBlogContent,
            },
          });
  
          return;
        }

        fetchedBlog && setBlog(fetchedBlog);
      });
    };

    fetchBlogContent();
    
    // fetch the blog statistics
    fetchBlogStatistics(id, (err, fetchedStats) => {
      if (err) return;

      if (!fetchedStats) return;

      setStats(fetchedStats);
    });
    
    fetchItemRecommendations(id, "blogs", "blog", (err, payload) => {
      if (err) {
        showSnackbar({
          hide: hideSnackbar,
          isError: true,
          title: "Unable to fetch recommended blogs",
        });

        return;
      }

      if (!payload) return;
    
      fetchBlogs(payload, (err, recommendedBlogs) => {
        if (err) return;

        if (!recommendedBlogs) return;

        setRecommendedBlogs(recommendedBlogs);
      });
    });
    
    return () => {
      removeHeaderActions();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: Delegate the fetching of blogs after altering recommendations to a
  //       useEffect hook instead of having the same lines of code twice to
  //       cater for the same need
  const saveRecommendations = (recommendations: string[]) => {
    setItemRecommendations(
      `${id}`, "blogs", "blog", recommendations,
      (err, savedRecommendations) => {
        if (err) {
          showSnackbar({
            isError: true,
            hide: hideSnackbar,
            title: "Unable to save recommendations",
            action: {
              label: "Retry",
              onDismiss: saveRecommendations.bind(this, recommendations),
            },
          });

          return;
        }

        if (!savedRecommendations) return;

        fetchBlogs(savedRecommendations, (err, populatedBlogs) => {
          if (err) return;

          if (!populatedBlogs) return;

          setRecommendedBlogs(populatedBlogs);
        });
      },
    );
  };

  // this is the loading screnn while the package is being fetched
  if (!blog) {
    return (
      <div
        className="w-full h-full flex flex-row p-4 space-x-4"
        style={{
          height: "calc(100vh - 56px)",
          animationDuration: "1s",
        }}
      >
        <div
          className="flex-1 max-w-[344px] h-full bg-disabled animate-pulse rounded-2xl"
        ></div>
        <div className="flex flex-col flex-1 rounded-2xl space-y-4">
          <div className="w-full h-[192px] bg-disabled animate-pulse rounded-2xl">
          </div>
          <div className="flex-1 w-full flex flex-row space-x-4 bg-disabled animate-pulse">
            {/* <div className=" bg-disabled animate-pulse rounded-2xl flex-1">
            </div>
            <div className="flex-shrink-0 flex-grow-0 bg-disabled w-[300px] rounded-2xl"></div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="w-full h-full flex flex-row"
      style={{
        height: "calc(100vh - 56px)",
      }}
    >
      <div
        className="no-scrollbar flex-1 max-w-[344px] border-r h-full space-y-4 p-4 overflow-scroll"
      >
        <div>
          <h4 className="text-3xl font-semibold">
            Summary Statistics
          </h4>
          <p className="text-subtitle">
            Summary statistics for the blog
          </p>
        </div>
        <StatsCard
          suffix="Reads"
          title="Total reads"
          value={blog.views.length || 0}
        />

        <StatsCard
          suffix="Comments"
          title="Total Comments"
          value={blog.comments.length}
        />

        {/* <StatsCard
          suffix="FT"
          title="Total Follow Throughs"
          value={12345}
        /> */}

        <hr />

        <div className="space-y-2">
          <div className="space-y-1">
            <h6 className="font-semibold">
              Graph showing the reads for the last 7 days
            </h6>
          </div>

          <Line
            datasetIdKey="label"
            data={{
              labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
              datasets: [
                {
                  label: "",
                  data: stats.reads,
                },
              ],
            }}
          />
        </div>
      </div>

      <div className="no-scrollbar flex-1 h-full overflow-scroll p-4 space-y-4">
        {/* Image carousel */}
        <ImageCarousel gallery={blog.gallery} />

        <div className="space-y-2">
          <h1 className="text-5xl font-semibold">
            {blog.title}
          </h1>
          {/* <h6 className="text-sub text-subtitle">
            This is the Subtitle
          </h6> */}
        </div>

        <div
          className="flex-1"
          dangerouslySetInnerHTML={{
            __html: blog.content,
          }}
        >
        </div>

        <hr />

        <div className="space-y-4">
          <div className="flex flex-row items-center justify-between">
            <span>
              <h4 className="font-semibold text-xl">
                Recommended Blogs
              </h4>
              <p className="font-semibold opacity-75">
                These are the blogs that will be recommended as alternatives
                to the user after reading this one
              </p>
            </span>

            <PencilIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => {
                setOverlay(() => (
                  <RecommendedItemsOverlay
                    items={recommendedBlogs}
                    setItems={(items) => {
                      saveRecommendations(
                        (items as Array<Blog>).map((item) => item.id),
                      );
                    }}
                    removeOverlay={removeOverlay}
                    fetchAllItems={fetchAllBlogs}
                  />
                ), true);
              }}
            />
          </div>

          {/* Render those packages */}
          <div className="flex flex-row">
            {recommendedBlogs.map((recommendedPackage) => (
              <PackageCard
                key={recommendedPackage.id}
                cover={recommendedPackage.gallery[0]}
                description={recommendedPackage.content}
                saves={recommendedPackage.views.length}
                subtitle={`${recommendedPackage.views} views`}
                title={recommendedPackage.title}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
