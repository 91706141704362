import {FC} from 'react';
import {IconType} from 'react-icons';

export interface IFAB {
  label: string;
  action: () => void;
  Icon: IconType;
}

type FabProps = {
  className?: string;
  Icon: IconType;
  action: () => void;
};

export const Fab:FC<FabProps> = ({
  className = "", Icon, action,
}) => {
  return (
    <div
      className={
        "fab h-[56px] w-[56px] flex items-center justify-center text-primary " +
        "bg-primary-container shadow-md " +
        className
      }
      onClick={action}
    >
      <Icon size={24} />
    </div>
  );
};

type FabExtendedProps = {
  className?: string;
  label: string;
  Icon: IconType;
  action: () => void;
};

export const FabExtended: FC<FabExtendedProps> = ({
  className = "", label, Icon, action,
}) => {
  return (
    <div
      className={
        "fab h-[56px] min-w-[80px] w-max rounded-2xl font-semibold " +
        "flex flex-row items-center px-4 shadow-md " +
        "text-primary bg-primary-container cursor-pointer " +
        className
      }
      style={{
        textOverflow: "unset",
      }}
      onClick={action}
    >
      <Icon size={24} className="mr-4 flex-shrink-0" />
      <p className="flex-shrink-0 overflow-visible">{label}</p>
    </div>
  );
};
