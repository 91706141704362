import {FC} from 'react';

import {AntDesign} from '.';
import {Picture} from '../@types';

type ListItemProps = {
  className?: string;
  title: string;
  description: string;
  avatar: Picture;
  onClick?: () => void;
  badge?: number;
};

export const ListItem: FC<ListItemProps> = ({
  className = "", badge = 0, title, avatar, description, onClick,
}) => {
  return (
    <div
      className={
        `h-[72px] w-[344px] flex flex-row items-center justify-between ${
          className
        } shadow-sm border-b px-4 cursor-pointer hover:bg-black ${
          ""
        } hover:bg-opacity-10 transition-all ease-linear overflow-hidden border`
      }
      onClick={onClick}
    >
      {/* avatar */}
      <div
        className="h-10 aspect-square rounded-full bg-center bg-cover bg-no-repeat flex-srink-0 mr-4"
        style={{
          backgroundImage: `url(${avatar.url})`,
        }}
      >
      </div>

      <div className="flex-1 flex flex-col justify-center overflow-hidden">
        <p className="font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis">
          {title}
        </p>

        <p className="text-subtitle whitespace-nowrap overflow-hidden overflow-ellipsis">
          {description}
        </p>
      </div>

      {badge > 0 && (
        <div className="justify-self-end flex-shrink-0">
          <AntDesign.Badge className="ml-4 justify-self-end" count={badge} />
        </div>
      )}
    </div>
  );
};
