import {
  Dispatch, FC, SetStateAction, useContext, useEffect, useState,
} from "react";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import Lottie from "react-lottie";
import {InformationCircleIcon} from "@heroicons/react/solid";

import {Button} from "../../components";
import {TextInput} from "../../components/input/text-input";
import {AppContext} from "../../context";
import {Validator} from "../../util";

import securityAnimation from '../../assets/security.json';

type ResetProps = {
  setSigninType: Dispatch<SetStateAction<"signin" | "signup" | "reset">>;
};

export const Reset: FC<ResetProps> = ({
  setSigninType,
}) => {
  const {methods} = useContext(AppContext);

  const [email, setEmail] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [userEmailError, setUserEmailError] = useState<boolean>(true);

  const [emailSent, setEmailSent] = useState<boolean>(false);

  // hook to validate email
  useEffect(() => {
    setEmailVerified(Validator.verifyEmail(email));
  }, [email]);

  const resetUserEmail = async () => {
    // resets the user email
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
    } catch (err) {
      console.error(err);
      setUserEmailError(true);

      methods.showSnackbar({
        hide: methods.hideSnackbar,
        isError: true,
        title: "Unable to send password reset email.",
        action: {
          label: "Try Again",
          onDismiss: resetUserEmail,
        },
      });
    }
  };
  
  return (
    <div className="h-full w-full">
      <header
        className={
          "h-[56px] w-full border-b flex flex-row items-center justify-center"
        }
      >
        <h6
          className="text-lg sm:text-3xl outline-1 text-transparent text-center"
          style={{
            WebkitTextStroke: "1px #702963",
          }}
        >
          Global Perspective Safaris
        </h6>
      </header>

      <div
        className={
          "w-full flex flex-col justify-center items-center sm:flex-row " +
          "sm:space-x-4"
        }
        style={{
          height: "calc(100vh - 56px)",
        }}
      >
        <div className="w-80 border rounded-lg p-4 space-y-4">
          <h6 className="text-subtitle text-center font-bold">
            Reset you GPSafaris email password
          </h6>

          <TextInput
            disabled={emailSent}
            inputLabel="Email"
            name="email"
            helper="Enter your email"
            id="signup-email"
            value={email}
            type="email"
            error={
              (!emailVerified && email !== "") ?
                "Please enter a valid email" :
                ""
            }
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <div>
            {!emailSent && (
              <>
                <Button
                  disabled={!emailVerified || loading}
                  className="mx-auto"
                  label="Sign up"
                  mode="solid"
                  type="button"
                  onClick={resetUserEmail}
                />
                {userEmailError && (
                  <p className="text-[11px] text-red-600 text-center mt-2">
                    {userEmailError}
                  </p>
                )}
              </>
            )}

            {emailSent && (
              <Button
                className="slide-in mx-auto"
                label="Back to sign in"
                mode="solid"
                type="button"
                onClick={() => {
                  // navigate("/signup/");
                  setSigninType("signin");
                }}
              />
            )}
          </div>

          <div
            className={
              "bg-notification flex flex-row items-center bg-error " +
              "shadow-md rounded-lg px-4 py-2 space-x-3"
            }
          >
            <InformationCircleIcon
              className="text-white flex-shrink-0 h-9 w-9"
            />
            <p className="text-white">
              Upon successful reset, you will receive an email, click on the
              link contained in the email to finish the password reset
            </p>
          </div>
        </div>

        <div className="hidden sm:block">
          <Lottie
            options={{
              animationData: securityAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={400}
            width={400}
          />
        </div>
      </div>
    </div>
  );
};
