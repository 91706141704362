import {doc, getDoc, getFirestore} from 'firebase/firestore';

import {WeekSummary} from '../@types';

import {OnCompleteCallback} from '.';

export const fetchSummary = async (
  onComplete: OnCompleteCallback<WeekSummary>,
): Promise<void> => {
  try {
    const weekId = generateWeekId(new Date());
    const db = getFirestore();
    const docReference = doc(db, "summary", weekId);
    const weekSummary = await getDoc(docReference);

    onComplete(null, weekSummary.data() as WeekSummary);
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const fetchWeekSummary = async (
  date: Date, onComplete: OnCompleteCallback<WeekSummary>,
): Promise<void> => {
  try {
    const db = getFirestore();
    const weekId = generateWeekId(date);
    const docReference = doc(db, "summary", weekId);
    const weekSummary = await getDoc(docReference);

    onComplete(null, weekSummary.data() as WeekSummary);
  } catch (err) {
    onComplete(err as Error, null);
  }
};

export const generateWeekId = (date: Date): string => {
  // check the date and make sure that it is a Sunday
  if (date.getDay() !== 0) {
    // this moves back the week to the previous Sunday, unless the current day
    // is Sunday, in which case it will stick to the same date as the day will
    // be 0
    date.setDate(date.getDate() - date.getDay());
  }

  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};
