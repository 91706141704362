import {FC} from 'react';

import {Picture} from '../@types';

type CardProps = {
  title: string;
  description: string;
  image: Picture;
  onClick?: () => void;
};

export const Card: FC<CardProps> = ({
  title, description, image, onClick,
}) => {
  return (
    <div
      className={
        "card m-[8px] relative w-[344px] h-[274px] aspect-square border shadow-md " +
        "bg-center bg-cover bg-no-repeat " +
        "overflow-hidden rounded-2xl"
      }
      style={{
        backgroundImage: `url(${image.url})`,
      }}
      onClick={onClick}
    >
      {/* first layer */}
      <div
        className={
          "first-layer absolute top-0 right-0 bottom-0 left-0 p-4 " +
          "bg-gradient-to-b from-transparent to-white"
        }
      >
        <div
          className="space-y-2 absolute right-0 bottom-0 left-0 p-4 overflow-x-hidden"
        >
          <h6 className="text-lg font-semibold text-primary">
            {title}
          </h6>
          <p className="overflow-ellipsis whitespace-nowrap overflow-hidden">
            {description}
          </p>
        </div>
      </div>

      {/* second layer */}
      <div
        className="second-layer absolute top-0 right-0 bottom-0 left-0 z-10 bg-transparent bg-shadow pb-4"
      >
        <div
          className="dissolve h-1/5 w-full bg-gradient-to-b from-transparent to-black"
        ></div>
        <div
          className="slide-in bg-white h-4/5 w-full p-4 overflow-hidden space-y-2"
        >
          <h6 className="text-lg font-semibold text-primary">
            {title}
          </h6>
          <p>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};
