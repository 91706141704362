import {FC, useState} from 'react';

import {AntDesign, TypeWriter} from '../../components';

import {Login} from './login';
import { Reset } from './reset';
import {Signup} from './signup';

const TEXTS = [
  "Manage, create and monitor performance of GPSafaris' Blogs.",
  "Manage, create and monitor performance of GPSafaris' Packages.",
  "Engage with GPSafaris' website users and convert them to paying customers.",
];

export const AuthContainer: FC = () => {
  const [signinType, setSigninType] = useState<'signin' | 'signup' | 'reset'>('signin');
  const [counter, setCounter] = useState<number>(0);

  const cycleText = () => {
    setCounter((counter + 1) % TEXTS.length);
  };

  if (signinType === "reset") {
    return (
      <Reset setSigninType={setSigninType} />
    );
  }
  
  return (
    <div
      className="w-screen h-screen flex flex-col"
    >
      <div className="h-[72px] flex flex-row items-center justify-center px-8">
        <h4 className="text-[20px] font-bold ml-8"
          style={{
            backgroundImage: "linear-gradient(90deg, #702963 0%, #FFD700 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          Global Perspective Safaris
        </h4>
      </div>
      <div
        className="flex flex-row justify-evenly items-center"
        style={{
          minHeight: "calc(100vh - 72px)",
        }}
      >
        <div className="w-80 flex-shrink-0 overflow-x-hidden">
          <AntDesign.Tabs
            className="w-80 self-center"
            animated={{tabPane: true}}
            activeKey={signinType}
            renderTabBar={() => <></>}
          >
            <AntDesign.Tabs.TabPane tab="Sign in" key="signin">
              <div
                className={
                  "justify-self-center self-center flex flex-col items-center " +
                  "transition-all"
                }
                style={{
                  transition: "display 0.5s ease-in-out",
                }}
              >
                <Login setSigninType={setSigninType} />
                <div
                  className="flex flex-row items-center justify-center py-2 px-4 w-80"
                >
                <p className="text-xs text-black mr-2">
                  Dont have an account?
                  <button
                    className="bg-transparent mx-0 my-0 text-xs text-blue-500"
                    onClick={() => {
                      setSigninType('signup');
                    }}
                  >
                    Sign up
                  </button>
                </p>
                </div>
              </div>
            </AntDesign.Tabs.TabPane>
            <AntDesign.Tabs.TabPane tab="Sign up" key="signup">
              <div
                className={
                  "justify-self-center self-center flex flex-col items-center " +
                  "transition-all h-full"
                }
              >
                <Signup />
                <div
                  className="flex flex-row items-center justify-center py-2 px-4 w-80"
                >
                <p className="text-xs text-black mr-2">
                  Have an account already?
                  <button
                    className="bg-transparent mx-0 my-0 text-xs text-blue-500"
                    onClick={() => {
                      setSigninType('signin');
                    }}
                  >
                    Sign in
                  </button>
                </p>
                </div>
              </div>
            </AntDesign.Tabs.TabPane>
          </AntDesign.Tabs>
        </div>

        {/* make shift border */}
        <div
          className="w-[1px] h-full bg-disabled"
          style={{
            maxHeight: "calc(100vh - 144px)",
          }}
        ></div>

        {/* Advertise what one can do on this site */}
        <div className="w-1/2 flex-shrink-0 self-center">
          <TypeWriter
            text={TEXTS[counter]}
            onComplete={cycleText}
          />
        </div>
      </div>
    </div>
  );
};
