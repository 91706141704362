import {FC, useState} from 'react';
import {XIcon} from '@heroicons/react/outline';
import {MdCloudUpload, MdImage} from 'react-icons/md';

import {AdminUser, Picture} from '../@types';
import {uploadUserPicture} from '../api/admin-user';

import {Button} from './button';

type ProfilPictureUploadProps = {
  user: AdminUser;
  removeOverlay: () => void;
};

export const ProfilPictureUpload: FC<ProfilPictureUploadProps> = ({
  user, removeOverlay,
}) => {
  // form state
  const [imageUrl, setImageUrl] = useState<string | undefined>(user.avatar?.url);
  const [picture, setPicture] = useState<Picture | undefined>(user.avatar);

  // UI State
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const updateUserPicture = () => {
    if (!picture) return;

    setIsUploading(true);
    uploadUserPicture(user, picture, (err) => {
      setIsUploading(false);
      if (err) {
        console.error(err);

        return;
      }

      removeOverlay();
    });
  };

  return (
    <div className="bg-white shadow-lg w-max pb-4">
      <div className="w-full h-12 px-4 mb-4 flex flex-row items-center border-b">
        <XIcon
          className="h-[24px] w-[24px] cursor-pointer mr-4"
          onClick={() => {
            // should not allow cancelling if the image is uploading
            if (isUploading) return;

            removeOverlay();
          }}
        />
        <h6 className="font-semibold">Upload user profile picture</h6>
      </div>

      <div className="space-y-4">
        <label htmlFor="user-profile-pic">
          <div
            className={
              "relative h-32 w-32 border rounded-full mx-auto cursor-pointer " +
              "overflow-hidden bg-center bg-cover bg-no-repeat"
            }
            style={{
              backgroundImage: imageUrl ? `url(${imageUrl})` : "",
            }}
          >
            <div
              className={
                "absolute top-0 right-0 bottom-0 left-0 " +
                "flex items-center justify-center " +
                // if their is an existing image, this acts as a semi-transparent
                // overlay
                `${imageUrl ?
                    "bg-scrim text-white" :
                    "bg-white text-black"
                }`
              }
            >
              <MdImage size={24} />
            </div>
            {/* this input is hidden from view */}
            <input
              hidden
              type="file"
              name="user_profile_picture"
              id="user-profile-pic"
              onChange={(e) => {
                if (e.target.files) {
                  const fileUrl = URL.createObjectURL(e.target.files[0]);
                  setPicture({
                    caption: `${user.uid} profile picture`,
                    name: e.target.files[0].name,
                    url: fileUrl,
                    file: e.target.files[0],
                  });
                  setImageUrl(fileUrl);
                }
              }}
            />
          </div>
        </label>
        <Button
          label="Upload"
          mode="solid"
          disabled={isUploading}
          type="button"
          className="mx-auto"
          Icon={MdCloudUpload}
          onClick={updateUserPicture}
        />
      </div>
    </div>
  );
};
