import {useState, useContext, createRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {BsClipboardPlus} from 'react-icons/bs';
import {CashIcon, HashtagIcon, PencilAltIcon} from '@heroicons/react/outline';
import {EditorState} from 'draft-js';

import {
  AntDesign, ImageCollectionOverview, ImageUploadModal, Input as TextInput,
  InputChip, TimelineEdit, ContentCreator, Uploading,
} from '../../../components';
import {AppContext} from '../../../context';
import {Itinerary, Package, Picture} from '../../../@types';
import { addNewPackage } from '../../../api';

export const NewPackage = () => {
  const navigate = useNavigate();

  // Context
  const {methods} = useContext(AppContext);
  // ContentCreator ref
  const [contentCreatorRef, ] = useState(createRef<ContentCreator>());
  // Form state
  const [title, setTitle] = useState<string>("");
  const [events, setEvents] = useState<Itinerary[]>([]);
  const [price, setPrice] = useState<number>(0);
  const [tag, setTag] = useState<string>("");
  const [gallery, setGallery] = useState<Picture[]>([]);
  const [location, setLocation] = useState<string>("");

  // content creator state
  const [editorState, ] =
    useState<EditorState>(EditorState.createEmpty());

  // all tags
  const [tags, setTags] = useState<string[]>([]);

  const removeTags = (idx: number) => {
    const newTags = [...tags];
    newTags.splice(idx, 1);
    setTags(newTags);
  };

  // form input validation
  const titleValid = title.length > 0 && /[A-Za-z0-9]+/g.test(title) === true;
  const eventsValid = events.length > 0;
  const priceValid = price > 0;
  const galleryValid = gallery.length > 0;
  const tagsValid = tags.length > 0;
  const locationValid = location.length > 0;

  const createPackage = () => {
    if (!contentCreatorRef.current) return;

    // add an upload overlay, that will either resolve with an error, or
    // navigating to all packages with a snackbar about the successfull upload
    methods.setOverlay(() => (
      <Uploading />
    ), false);

    // NOTE: This is a temporary stub to imitate the actual upload process
    //       and to show the upload overlay.


    // get the max day from the events
    let duration = 1;

    events.forEach((event) => {
      // update the duration accordingly
      event.dayNumber > duration && (duration = event.dayNumber);
    });

    const newPackage: Package = {
      // NOTE: This will be replaed with a generated id when package is saved
      id: "",
      title,
      itinerary: events,
      content: contentCreatorRef.current.getHTML(),
      price,
      gallery,
      tags,
      createdAt: Date.now(),
      duration,
      lastedited: Date.now(),
      location,
      saves: [],
      views: [],
    };

    addNewPackage(newPackage, (err, res) => {
      methods.removeOverlay();
      
      if (err) {
        console.error(err);
        methods.showSnackbar({
          hide: methods.hideSnackbar,
          isError: true,
          title: "An Error occured creating package",
        });

        return;
      }

      navigate("/packages", {replace: true});

      // show a snackbar about the successfull upload
      methods.showSnackbar({
        hide: methods.hideSnackbar,
        isError: false,
        title: "Package created successfully",
      });
    });
    // console.info("Created Package: ", newPackage);

  };

  // once the component is mounted, we want create the header actions, and also
  // watch for the inputs to know when to enable and disable these components
  useEffect(() => {
    // form input validation
    const titleValid = title.length > 0 && /[A-Za-z0-9]+/g.test(title) === true;
    const eventsValid = events.length > 0;
    const priceValid = price > 0;
    const galleryValid = gallery.length > 0;
    const tagsValid = tags.length > 0;

    const disabled = !titleValid || !eventsValid || !priceValid ||
      !galleryValid || !tagsValid;

    // TODO: Finalise with the api calls to create/draft the package
    methods.setHeaderActions([
      null,
      {
        label: "Publish",
        onClick: () => createPackage(),
        disabled,
      },
    ]);

    return () => {
      methods.removeHeaderActions();
    };
  }, [title, tags, events, gallery, price]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full h-full flex flex-row z-0">
      {/* We make this container sticky relative to the main app container */}
      <div
        className={
          "no-scrollbar w-96 border-r sticky top-[56px] space-y-4 p-4 " +
          "overflow-y-scroll flex-shrink-0"
        }
        style={{height: "calc(100vh - 56px)"}}
      >
        <div className="space-y-1">
          <h4 className="text-3xl font-semibold">Miscalleneous</h4>
          <p className="text-subtitle">
            This section contains miscalleneous info about the package.
          </p>
        </div>
        <TextInput
          helper="This is the title the user will see"
          inputLabel="Tour Package Title"
          id="package-title"
          name="package_title"
          type="text"
          value={title}
          error={!titleValid ? "Title is required" : ""}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />

        <TextInput
          helper="This is the location of the main destination"
          id="package_location"
          inputLabel="Location"
          name="package_location"
          type="text"
          value={location}
          error={!locationValid ? "Location is required" : ""}
          onChange={(e) => setLocation(e.target.value)}
        />

        {/* gallery images */}
        <div className="space-y-2">
          <span className="flex flex-row items-center justify-between">
            <h4 className="text-xl font-semibold">Gallery</h4>
            <PencilAltIcon
              className="h-6 w-6 text-black cursor-pointer"
              onClick={() => {
                // create an overlay that will allow the addition of new
                // pictures
                methods.setOverlay(() => (
                  <ImageUploadModal
                    images={gallery}
                    title="Upload new images"
                    removeOverlay={methods.removeOverlay}
                    onSave={(newPictures) => {
                      setGallery(newPictures);
                    }}
                  />
                ), false);
              }}
            />
          </span>

          <ImageCollectionOverview
            pictures={gallery}
          />

          {/* Handy message to the user that the images are not filled in */}
          {!galleryValid && (
            <div className="space-y-4">
              <p className="text-center opacity-60">
              There are no images added to the package gallery right now, please
              add at least one.
              </p>
              <hr />
            </div>
          )}
        </div>

        {/* Timeline of the events that the user will partake in*/}
        <div className="space-y-2 flex flex-col justify-start">
          <span className="flex flex-row items-center justify-between">
            <span>
              <h4 className="text-xl font-semibold">Timeline</h4>
              <p className="text-[11px] text-subtitle">
                This details all the user will do
              </p>
            </span>
            <BsClipboardPlus
              onClick={() => {
                // set the overlay
                methods.setOverlay(
                  () => (
                    <TimelineEdit
                      items={events}
                      removeOverlay={methods.removeOverlay}
                      onSave={(newEvents) => {
                        setEvents(newEvents);
                      }}
                    />
                  ),
                  false,
                );
              }}
              className="h-6 w-6 text-black cursor-pointer"
            />
          </span>
          {!eventsValid && (
            <div className="p-4">
              <p className="text-center opacity-60">
                There are currently no added items to the itinerary, press on
                the clipboard icon to add any new items to the itinerary. Make
                sure to add at least one item to the itinerary.
              </p>
            </div>
          )}
          {events.length > 0 && (
            <AntDesign.Timeline mode="left">
              {events.map((event) => (
                <AntDesign.Timeline.Item  
                  key={`package_itinerary_${event.dayNumber}_${event.time}`}
                  label={`Day ${event.dayNumber}`}
                >
                  {event.title}
                </AntDesign.Timeline.Item>
              ))}
            </AntDesign.Timeline>
          )}
        </div>

        <hr />

        <div>
          <span className="flex flex-row items-center">
            <CashIcon
              className="h-6 w-6 text-black mr-4"
            />
            <div>
              <h6 className="text-lg font-semibold">Payment (Pricing)*</h6>
              <p className="text-[11px] opacity-70">
                *this pricing option is per person sharing
              </p>
            </div>
          </span>

          <TextInput
            className="my-2"
            helper="Price per person sharing i (in USD)"
            error={!priceValid ? "Price is required" : ""}
            id="price"
            inputLabel="Price (in USD)"
            name="price"
            type="number"
            value={`${price}`}
            onChange={(e) => {
              setPrice(parseFloat(e.target.value));
            }}
          />
        </div>
        <hr />
        <div>
          <span className="flex flex-row items-center">
            <HashtagIcon
              className="h-6 w-6 text-black mr-4"
            />
            <div>
              <h6 className="text-lg font-semibold">Searchable Tags</h6>
              <p className="text-[11px] opacity-70">
                Add at least one. This are important for SEO
              </p>
            </div>
          </span>
          {/* 
            This is a make shift solution to make sure we remove the need for
            a button to submit, as such we wrap the input in a form and use
            the onSubmit event to add the tag to the tags array and also prevent
            the default behaviour to make sure the page doesn't refresh
           */}
          <form
            onSubmit={(e) => {
              e.preventDefault()
              setTags([...tags, tag]);
              setTag("");
            }}
          >
            <TextInput
              className="my-2"
              value={tag}
              type="text"
              helper="Add the tag name here"
              id="package-tag"
              name="package_tag"
              inputLabel="tag"
              onChange={(e) => {
                setTag(e.target.value);
              }}
            />
          </form>
          {/* render all the tags as InputChips */}
          <div className="flex flex-row flex-wrap">
            {tags.map((tag, idx) => (
              <InputChip
                className="flex-shrink-0 m-2"
                key={`hashtag_${idx}`}
                label={tag}
                onRemove={removeTags.bind(this, idx)}
              />
            ))}
          </div>
          {!tagsValid && (
            <div className="space-y-4">
              <p className="text-center opacity-60">
              There are no tags added to the package right now, please
              add at least one to improve search engin optimization
              </p>
              <hr />
            </div>
          )}
        </div>
      </div>
      <div
        className="bg-black bg-opacity-5"
        style={{minHeight: "calc(100vh - 56px)"}}
      >
        <ContentCreator
          ref={contentCreatorRef}
          initialEditorState={editorState}
        />
      </div>
    </div>
  );
};
